
import { currentStore, paneStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import PaneListFractions from '@/components/panes/PaneListFractions.vue'
import PaneListBuildingParts from '@/components/panes/PaneListBuildingParts.vue'
import PaneListRemediationTasks from '@/components/panes/PaneListRemediationTasks.vue'
import PaneListParadigms from '@/components/panes/PaneListParadigms.vue'
import PaneParadigm from '@/components/panes/PaneParadigm.vue'
import PaneListSamples from '@/components/panes/PaneListSamples.vue'
import PaneBuildingsAndFloorPlans from '@/components/panes/PaneBuildingsAndFloorPlans.vue'
import PaneSample from '@/components/panes/PaneSample.vue'
import PaneBuildingPart from '@/components/panes/PaneBuildingPart.vue'
import PaneFraction from '@/components/panes/PaneFraction.vue'
import PaneOnlineType from '@/components/panes/PaneOnlineType.vue'
import PaneResaleOnlineType from '@/components/panes/PaneResaleOnlineType.vue'
import PaneUnit from '@/components/panes/PaneUnit.vue'
import PaneFeaturedResale from '@/components/panes/PaneFeaturedResale.vue'

import PaneResale from '@/components/panes/PaneResale.vue'

const below1200px = '(max-width: 1199px)'
const mediaQueryList = window.matchMedia(below1200px)

const useEventListener = !!mediaQueryList.addEventListener

export default defineComponent({
  name: 'PaneContainer',
  props: {
    firstPane: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      paneIDs: [] as string[],
      paneMinWidth: {
        px: 0,
        pct: 0,
      },
      supportedPaneCount: 1,
      isMounted: false,
    }
  },
  computed: {
    ...mapState(currentStore, ['onlineTypes']),
    paneRootMaxWidth(): { [key: string]: string | number } {
      // No max-width when only pane can be visible.
      if (this.supportedPaneCount <= 2) {
        return { maxWidth: '100%' }
      }

      const multiplier = !this.paneIDs.length
        ? 2
        : // + 1 is to give a bit more realstate going from 1 pane to 'n' pane
          Math.min(this.paneIDs.length + 1, this.supportedPaneCount) + 1

      return { maxWidth: `${this.paneMinWidth.pct * multiplier}%` }
    },
    paneSliderTransition(): { [key: string]: string | number } {
      if (!this.paneIDs.length) {
        return {}
      }

      if (this.supportedPaneCount === 1) {
        return {
          transform: `translateX(-${this.paneIDs.length * 100}%)`,
        }
      }

      if (this.supportedPaneCount === 2) {
        return {
          transform: `translateX(-${(this.paneIDs.length - 1) * 50}%)`,
        }
      }

      if (this.paneIDs.length + 1 <= this.supportedPaneCount) {
        return {}
      }

      const multiplier = this.paneIDs.length + 1 - this.supportedPaneCount

      return {
        transform: `translateX(-${this.paneMinWidth.pct * multiplier}%)`,
      }
    },
    paneWidth(): { [key: string]: string | number } {
      if (this.supportedPaneCount <= 2) {
        return { width: `${100 / this.supportedPaneCount}%` }
      }

      if (this.paneIDs.length + 1 < this.supportedPaneCount) {
        return { width: `${100 / (this.paneIDs.length + 1)}%` }
      }

      return { width: `${100 / this.supportedPaneCount}%` }
    },
  },
  watch: {
    '$route.params.paneIDs': {
      handler(urlParts) {
        this.paneIDs = urlParts ? urlParts : []
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions(paneStore, ['getPaneComponentName']),
    allowTransitions() {
      this.isMounted = true
    },
    stopAtPreviousPane(index: number) {
      const previousPaneID = this.paneIDs[index - 1]

      if (!previousPaneID) {
        this.$router.push({ name: this.$route.name as string })
        return
      }

      const panesList = this.paneIDs.slice(0, index)
      const currentPage = this.$route.path.split('/').slice(0, 4).join('/')

      this.$router.push(`${currentPage}/${panesList.join('/')}`)

      return
    },
    getPane(id: string) {
      const paneName = this.getPaneComponentName(id)

      if (!paneName) {
        const faultyPaneIndex = this.paneIDs.indexOf(id)
        const panesList = this.paneIDs.slice(0, faultyPaneIndex)
        const currentPage = this.$route.path.split('/').slice(0, 4).join('/')

        this.$router.push(`${currentPage}/${panesList.join('/')}`)
      }

      return paneName
    },
    setSupportedPaneCount() {
      // Using parent of this component as target "viewport"
      const { width } = this.$el.parentNode.getBoundingClientRect()

      const supportedByViewport = Math.floor(width / this.paneMinWidth.px)

      // Set minimum panecount support to 1
      this.supportedPaneCount = supportedByViewport || 1
      this.paneMinWidth.pct = 100 / this.supportedPaneCount
    },
    setPaneMinPx({ matches }: { matches: boolean }) {
      // If viewport is below 1200px
      this.paneMinWidth.px = matches ? 540 : 480
    },
  },
  components: {
    PaneListFractions,
    PaneListBuildingParts,
    PaneListRemediationTasks,
    PaneListSamples,
    PaneBuildingsAndFloorPlans,
    PaneListParadigms,
    PaneResale,
    PaneFeaturedResale,
    PaneParadigm,
    PaneFraction,
    PaneBuildingPart,
    PaneOnlineType,
    PaneResaleOnlineType,
    PaneSample,
    PaneUnit,
  },
  created() {
    useEventListener
      ? mediaQueryList.addEventListener('change', this.setPaneMinPx)
      : mediaQueryList.addListener(this.setPaneMinPx)

    window.addEventListener('resize', this.setSupportedPaneCount)

    this.setPaneMinPx(mediaQueryList)
  },
  mounted() {
    this.setSupportedPaneCount()
  },
  beforeUnmount() {
    useEventListener
      ? mediaQueryList.removeEventListener('change', this.setPaneMinPx)
      : mediaQueryList.removeListener(this.setPaneMinPx)

    window.removeEventListener('resize', this.setSupportedPaneCount)
  },
})
