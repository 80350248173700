
import { defineComponent, defineAsyncComponent } from 'vue'
import { mapState } from 'pinia'
import { currentStore, paradigmStore } from '@/store'

import ParadigmUN from '@/components/paradigm/general/ParadigmUN.vue'
import ParadigmDGNB from '@/components/paradigm/general/ParadigmDGNB.vue'
import ParadigmValues from '@/components/paradigm/general/ParadigmValues.vue'
import ParadigmHandling from '@/components/paradigm/general/ParadigmHandling.vue'
import ParadigmRemediation from '@/components/paradigm/general/ParadigmRemediation.vue'
import ParadigmGWP from '@/components/paradigm/general/ParadigmGWP.vue'

const { project } = currentStore()
const isScreening = project?.data.isScreening ?? false

export default defineComponent({
  name: 'PaneParadigm',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, ['project']),
    ...mapState(paradigmStore, ['sections', 'sectionsAsObject']),
    paradigm() {
      return this.sectionsAsObject[this.id] ?? null
    },
  },
  components: {
    ParadigmIntro:
      project?.data.projectType === 'damage-related-screening'
        ? defineAsyncComponent(
            () =>
              import(
                '@/components/paradigm/damageRelatedScreening/ParadigmIntro.vue'
              )
          )
        : isScreening
        ? defineAsyncComponent(
            () => import('@/components/paradigm/screening/ParadigmIntro.vue')
          )
        : defineAsyncComponent(
            () => import('@/components/paradigm/mapping/ParadigmIntro.vue')
          ),
    ParadigmInspection: isScreening
      ? defineAsyncComponent(
          () => import('@/components/paradigm/screening/ParadigmInspection.vue')
        )
      : defineAsyncComponent(
          () => import('@/components/paradigm/mapping/ParadigmInspection.vue')
        ),
    ParadigmUN,
    ParadigmDGNB,
    ParadigmValues,
    ParadigmHandling,
    ParadigmRemediation,
    ParadigmGWP,
  },
})
