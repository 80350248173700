
import PaneLineBuildingPart from '@/components/panes/PaneLineBuildingPart.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import PageVideoGuide from '@/components/pageGuide/PageVideoGuide.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'

export default defineComponent({
  name: 'PaneListBuildingParts',
  computed: {
    ...mapState(currentStore, {
      buildingPartsWithOnlineTypes: ({ categoryGroupsByBuilding }) =>
        categoryGroupsByBuilding.withOnlineTypes,
      buildingPartsWithoutOnlineTypes: ({ categoryGroupsByBuilding }) =>
        categoryGroupsByBuilding.withoutOnlineTypes,
    }),
  },
  components: { PaneLineBuildingPart, PageVideoGuide, PaneTitle },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
