import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MarketInquiryFlow = _resolveComponent("MarketInquiryFlow")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "MarketInquiryButton",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShownDialog('marketInquiry')))
    }, _toDisplayString(_ctx.mixWB('MAKE_AN_OFFER')), 1),
    _createVNode(_component_Dialog, { name: "marketInquiry" }, {
      default: _withCtx(() => [
        _createVNode(_component_MarketInquiryFlow, { productDetails: _ctx.productDetails }, null, 8, ["productDetails"])
      ]),
      _: 1
    })
  ], 64))
}