
import { ICategoryGroup } from '@/__types__/categoryGroup'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaneLineBuildingPart',
  props: {
    buildingPart: {
      type: Object as PropType<ICategoryGroup>,
      required: true,
    },
    inResaleContext: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    paneLineIsActive() {
      const { paneIDs } = this.$route.params

      if (typeof paneIDs === 'string') {
        return false
      }

      return (
        this.buildingPart.hasOnlineTypes &&
        paneIDs.includes(this.buildingPart.hashID)
      )
    },
  },
})
