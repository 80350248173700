
import FloorPlans from '@/components/FloorPlans.vue'
import PaneLineInterConnection from '@/components/panes/PaneLineInterConnection.vue'
import { IBlockItem } from '@/components/partials/BlockDisplayBlock.vue'
import BlockDisplayWrap from '@/components/partials/BlockDisplayWrap.vue'
import Region from '@/components/Region.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import Building from '@/globals/javascript/models/proUnit/Building'
import { IImage } from '@/__types__/_pro/partials/image'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import CustomSlider from '@/components/CustomSlider.vue'
import Image from '@/components/Image.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'

export default defineComponent({
  name: 'PaneUnit',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, [
      'units',
      'onlineTypes',
      'project',
      'projectAddress',
    ]),
    unit() {
      return this.units.all.find((u) => u.hashID === this.id)
    },
    isBuilding() {
      return this.unit instanceof Building
    },
    unitTypeText() {
      if (this.unit instanceof Building) {
        return (
          this.unit?.getBuildingTypeCodeText() ?? this.mixWB('NOT_SPECIFIED')
        )
      }

      return ''
    },
    relatedOnlineTypes() {
      return this.onlineTypes
        .filter((o) => o.unitIDs.includes(this.unit?.id ?? ''))
        .sort((a: OnlineType, b: OnlineType) => {
          const aNumber = Number(`${a.index.primary}.${a.index.secondary}`)
          const bNumber = Number(`${b.index.primary}.${b.index.secondary}`)

          if (aNumber > bNumber) {
            return 1
          }
          if (aNumber < bNumber) {
            return -1
          }
          return 0
        })
    },
    images() {
      const images: IImage[] = []

      if (!this.unit) {
        return images
      }

      if (this.unit instanceof Building) {
        images.push(...this.unit.getProfileImages())
      }

      const oTypeImages = this.relatedOnlineTypes.map(
        (x) => x.types[0].images[0]
      )

      return [...images, ...oTypeImages]
    },
    samples() {
      return this.relatedOnlineTypes.map((x) => x.samples).flat(1)
    },
    details(): IBlockItem[] {
      // Only pass if unit is a Building instance
      if (!this.unit || !(this.unit instanceof Building)) {
        return []
      }

      const landOwnerTextParts: string[] = [
        this.unit.getLandRegistryNumber(),
        this.unit.getLandOwnerAssosiationName(),
      ].filter(Boolean)

      const landOwner: IBlockItem = {
        title: this.mixWB('LAND_OWNER_ASSCOSIATION'),
        text: landOwnerTextParts.length
          ? landOwnerTextParts.join(' - ')
          : this.mixWB('NOT_SPECIFIED'),
        isFullWidth: true,
        extraTexts: [],
      }

      const workYearsTextParts = [
        this.unit.getYearBuilt(),
        this.unit.getRemodelingYear(),
      ].filter(Boolean)

      const workYearsDefaultText = `${this.unit.getYearBuilt() ?? '-'} / ${
        this.unit.getRemodelingYear() || '-'
      }`
      const workYears: IBlockItem = {
        title: `${this.mixWB('YEAR_OF_CONSTRUCTION')} / ${this.mixWB(
          'REMODELING_YEAR'
        )}`,
        text: workYearsTextParts.length
          ? workYearsDefaultText
          : this.mixWB('NOT_SPECIFIED'),
        isFullWidth: false,
        extraTexts: [],
      }

      const builtArea: IBlockItem = {
        title: this.mixWB('BUILT_UP_AREA'),
        text: this.unit.getBuiltArea() ?? this.mixWB('NOT_SPECIFIED'),
        isFullWidth: false,
        extraTexts: [],
      }

      const atticArea: IBlockItem = {
        title: this.mixWB('UTILIZED_ATTIC_AREA'),
        text: this.unit.getUtilizedAtticArea() ?? this.mixWB('NOT_SPECIFIED'),
        isFullWidth: false,
        extraTexts: [],
      }

      const basementArea: IBlockItem = {
        title: this.mixWB('BASEMENT_AREA'),
        text: this.unit.getBasementArea() ?? this.mixWB('NOT_SPECIFIED'),
        isFullWidth: false,
        extraTexts: [],
      }

      const livingArea: IBlockItem = {
        title: this.mixWB('TOTAL_LIVING_AREA'),
        text: this.unit.getTotalLivingArea() ?? this.mixWB('NOT_SPECIFIED'),
        isFullWidth: false,
        extraTexts: [],
      }

      const buisinessArea: IBlockItem = {
        title: this.mixWB('TOTAL_BUSINESS_AREA'),
        text: this.unit.getTotalBusinessArea() ?? this.mixWB('NOT_SPECIFIED'),
        isFullWidth: false,
        extraTexts: [],
      }

      return [
        landOwner,
        workYears,
        builtArea,
        atticArea,
        basementArea,
        livingArea,
        buisinessArea,
      ]
    },
  },
  components: {
    Region,
    PaneLineInterConnection,
    FloorPlans,
    BlockDisplayWrap,
    CustomSlider,
    Image,
    PaneTitle,
  },
})
