
import TestResultGridSampleColumn from '@/components/partials/TestResultGridSampleColumn.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { resourcesStore } from '@/store'
import { chunk } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

const above650px = '(min-width: 651px)'
const mediaQueryList = window.matchMedia(above650px)

const useEventListener = !!mediaQueryList.addEventListener

export default defineComponent({
  name: 'TestResultGrid',
  props: {
    samples: {
      type: Array as PropType<ProSample[]>,
      required: true,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    ...mapState(resourcesStore, ['cleanAnalysisTestsAsArray']),
    chunkedSamples() {
      return this.isMobile ? [this.samples] : chunk(this.samples, 2)
    },
  },
  methods: {
    checkIfDisplayAbove650({ matches }: { matches: boolean }) {
      this.isMobile = matches ? false : true
    },
  },
  components: { TestResultGridSampleColumn },
  created() {
    useEventListener
      ? mediaQueryList.addEventListener('change', this.checkIfDisplayAbove650)
      : mediaQueryList.addListener(this.checkIfDisplayAbove650)

    this.checkIfDisplayAbove650(mediaQueryList)
  },
  beforeUnmount() {
    useEventListener
      ? mediaQueryList.removeEventListener(
          'change',
          this.checkIfDisplayAbove650
        )
      : mediaQueryList.removeListener(this.checkIfDisplayAbove650)
  },
})
