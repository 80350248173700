import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13241aa0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneParadigm CustomScrollbar" }
const _hoisted_2 = { class: "PaneIntro" }
const _hoisted_3 = { class: "List" }
const _hoisted_4 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_5 = { class: "Label" }
const _hoisted_6 = { class: "ListContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_PaneLink = _resolveComponent("PaneLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PaneTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.mixWB('PARADIGM')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('PARADIGM_PAGE_INTRO_TEXT_1')), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mixWB('INFORMATION')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: section.url
          }, [
            (!_ctx.isBlackListed(section.url))
              ? (_openBlock(), _createBlock(_component_PaneLink, {
                  key: 0,
                  class: "PaneLine",
                  "pane-id": section.url
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(section.index) + ". " + _toDisplayString(section.title), 1),
                    _createVNode(_component_InlineSVG, {
                      class: "Icon",
                      src: require('@/assets/svg/angle-right.svg')
                    }, null, 8, ["src"])
                  ]),
                  _: 2
                }, 1032, ["pane-id"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ]))
}