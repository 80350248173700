
import { IImage } from '@/__types__/_pro/partials/image'
import { appStore, currentStore } from '@/store'
import { union, uniqBy } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import Region from '@/components/Region.vue'
import Information from '@/components/partials/Information.vue'
import { IBlockItem } from '@/components/partials/BlockDisplayBlock.vue'
import BlockDisplayWrap from '@/components/partials/BlockDisplayWrap.vue'
import CustomSlider from '@/components/CustomSlider.vue'
import Image from '@/components/Image.vue'
import PaneLineInterConnection from '@/components/panes/PaneLineInterConnection.vue'
import MarketInquiry from '@/components/marketInquiry/MarketInquiry.vue'
import dayjs from 'dayjs'
import ToolTip from '@/components/ToolTip.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { ProType } from '@/globals/javascript/models/proType/ProType'
import { ProCoatingType } from '@/globals/javascript/models/proCoatingType/ProCoatingType'
import { IInfoText } from '@/__types__/utils/infoText'
import { HOST } from '@/globals/javascript/_utils/host'

export default defineComponent({
  name: 'PaneResaleOnlineType',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(appStore, ['isResale']),
    ...mapState(currentStore, ['onlineTypes', 'project']),
    oType(): OnlineType | null {
      return (
        this.onlineTypes.find((oType) => oType.resaleID === this.id) ?? null
      )
    },
    images(): IImage[] {
      if (!this.oType) {
        return []
      }

      return uniqBy(
        this.oType.types
          .map((pType) => pType.images)
          .flat(1)
          .filter(Boolean),
        'base.path'
      )
    },
    showMarketInquiryButton() {
      if (!this.project?.data.market) {
        return false
      }

      const { isApproved, expiryDate } = this.project.data.market
      const expirationDate = dayjs(expiryDate).startOf('date')
      const todaysDate = dayjs().startOf('date')
      const expiredOnMarked = expirationDate.isBefore(todaysDate)

      return isApproved && !expiredOnMarked
    },
    productDetails() {
      const data = {
        title: '',
        imageURL: '',
        url: '',
      }

      if (!this.oType) {
        return data
      }

      const { path } = this.images[0].base

      data.imageURL = `${HOST.cloudinary}/${path}?tx=t_square_200`
      data.title = this.oType.titles.complete
      data.url = window.location.href

      return data
    },
    quantityItems(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      /**
       * Amount
       */
      const amountTitle =
        this.oType.amounts.totalTons && this.oType.amounts.hasMissingAmounts
          ? `${this.mixWB('ESTIMATED_QUANTITY')} (${this.mixWB(
              'CERTAIN_QUANTITIES_ARE_MISSING'
            )})`
          : this.mixWB('ESTIMATED_QUANTITY')

      const amount: IBlockItem = {
        title: amountTitle,
        text: this.oType.getTonsTextWithDetails(),
        isFullWidth: true,
        extraTexts: [],
      }

      this.oType.amounts.amountsPerPType.forEach((pTypeAmountItem) => {
        amount.extraTexts.push(pTypeAmountItem.calculation)
      })

      this.oType.amounts.amountsPerCleaningTask.forEach((cleaningTaskItem) => {
        amount.extraTexts.push(cleaningTaskItem.calculation)
      })

      return [amount]
    },
    co2Items(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      const { co2Amounts } = this.oType

      const title =
        co2Amounts.totalTons && co2Amounts.hasMissingAmounts
          ? `${this.mixWB('POTENTIAL_SAVINGS')} (${this.mixWB(
              'CERTAIN_QUANTITIES_ARE_MISSING'
            )})`
          : this.mixWB('POTENTIAL_SAVINGS')

      const amount: IBlockItem = {
        title,
        text: this.oType.getCO2TextWithDetails(),
        isFullWidth: true,
        extraTexts: [],
        footNotes: [],
      }

      co2Amounts.amountsPerPType.forEach((pTypeAmountItem) => {
        amount.extraTexts.push(pTypeAmountItem.texts.calculation)
      })

      amount.footNotes?.push({
        pretext: this.mixWB('READ_MORE_ABOUT'),
        link: {
          href: '/project/J0ffoDhhcDJ04rVgTcqh/paradigm/global-warming-potential',
          text: this.mixWB('CO2_E_CALCULATION'),
        },
      })

      return [amount]
    },
    informationTexts() {
      let usedTypeIDs: string[] = []
      const uniqueCases: {
        caseID: string
        pTypes: (ProType | ProCoatingType)[]
        predefinedHeadline?: string
        answer?: 0 | 1 | 2
      }[] = []

      const infoTexts: IInfoText[] = []

      if (!this.oType) {
        return infoTexts
      }

      this.oType.specialCases.forEach((specialCase) => {
        let item = uniqueCases.find((x) => x.caseID === specialCase.caseID)

        if (!item) {
          item = {
            caseID: specialCase.caseID,
            pTypes: [],
            predefinedHeadline: specialCase.title ?? '',
            answer: specialCase.answer,
          }
          uniqueCases.push(item)
        }

        item.pTypes.push(specialCase.pType)
        if (specialCase.overrideNormalInformationText) {
          usedTypeIDs = union(usedTypeIDs, [specialCase.pType.id])
        }
      })

      uniqueCases.forEach((uniqueCase) => {
        if (uniqueCase.caseID === 'special-case-10') {
          let conclusion = this.mixWB('NOT_ASSESSED')
          if (uniqueCase.answer === 0) {
            conclusion = this.mixWB('NO')
          }
          if (uniqueCase.answer === 1) {
            conclusion = this.mixWB('YES')
          }

          if (this.oType) {
            infoTexts.push(
              this.oType.getInfoTextHeadlineFromTypes(uniqueCase.pTypes)
            )
            infoTexts.push({
              text: `${this.mixWB(
                'CAN_BRICKS_BE_CLEANED_OF_MORTAR_AND_REUSED'
              )}: ${conclusion}.`,
            })
          }
        }
      })

      return infoTexts
    },
    conditionItems() {
      if (!this.oType) {
        return []
      }

      const forceConditions =
        this.oType.types.length > 1 && this.oType.types.some((x) => x.condition)

      const conditions = this.oType.types.reduce((prev, proType) => {
        if (forceConditions || proType.condition) {
          prev.push({
            title: proType.getTitle(),
            text: proType.getConditionText() ?? '',
            isFullWidth: true,
            extraTexts: [],
          })
        }

        return prev
      }, [] as IBlockItem[])

      return conditions
    },
  },
  components: {
    Region,
    BlockDisplayWrap,
    Information,
    CustomSlider,
    Image,
    PaneLineInterConnection,
    MarketInquiry,
    ToolTip,
    PaneTitle,
  },
  created() {
    if (!this.oType) {
      this.$emit('data-not-found')
    }
  },
})
