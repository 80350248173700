
import Image from '@/components/Image.vue'
import ToolTip from '@/components/ToolTip.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaneLineInterConnection',
  props: {
    onlineType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
    type: {
      type: String as PropType<'inner' | 'outer'>,
      required: false,
    },
  },
  data() {
    return {
      prefix:
        this.type &&
        this.mixWB(this.type === 'inner' ? 'SITS_ON' : 'ON_THIS_SITS'),
    }
  },
  components: { Image, ToolTip },
})
