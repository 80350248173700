
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ParadigmHandling',
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  components: { PaneTitle },
})
