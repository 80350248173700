
import Region from '@/components/Region.vue'
import { defineComponent, PropType } from 'vue'

interface ITextItem {
  type?: 'header'
  text: string
}

export default defineComponent({
  name: 'Information',
  props: {
    texts: {
      type: Array as PropType<ITextItem[]>,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  computed: {
    heading() {
      return this.title ?? this.mixWB('INFORMATION')
    },
  },
  components: { Region },
})
