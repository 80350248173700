import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-281dc824"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneOnlineType CustomScrollbar" }
const _hoisted_2 = { class: "Meta" }
const _hoisted_3 = { class: "Legend" }
const _hoisted_4 = { class: "Legend" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "Legend" }
const _hoisted_7 = {
  key: 0,
  class: "NotRecyclable"
}
const _hoisted_8 = {
  key: 1,
  class: "NotRecyclable"
}
const _hoisted_9 = {
  key: 0,
  class: "Paragraph"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_Image = _resolveComponent("Image")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!
  const _component_PaneLineInterConnection = _resolveComponent("PaneLineInterConnection")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _component_Region = _resolveComponent("Region")!
  const _component_BlockDisplayWrap = _resolveComponent("BlockDisplayWrap")!
  const _component_Information = _resolveComponent("Information")!
  const _component_TestResultGrid = _resolveComponent("TestResultGrid")!
  const _component_FloorPlans = _resolveComponent("FloorPlans")!
  const _directive_gallery = _resolveDirective("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.oType)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_PaneTitle, { paneID: _ctx.id }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.oType.titles.complete), 1)
            ]),
            _: 1
          }, 8, ["paneID"]),
          _withDirectives(_createVNode(_component_CustomSlider, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                return (_openBlock(), _createBlock(_component_Image, {
                  key: image.base.path,
                  image: image,
                  dimensions: { width: 200, height: 200 },
                  transformation: 'square_200',
                  class: "Image_200"
                }, null, 8, ["image"]))
              }), 128))
            ]),
            _: 1
          }, 512), [
            [_directive_gallery, _ctx.id]
          ]),
          _createElementVNode("dl", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.mixWB('STATUS')) + ":", 1),
              _createElementVNode("dd", null, [
                _createElementVNode("span", {
                  class: _normalizeClass(["Label", _ctx.oType.status.className])
                }, _toDisplayString(_ctx.oType.status.text), 3)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.mixWB('UNITS')) + ":", 1),
              _createElementVNode("dd", null, _toDisplayString(_ctx.oType.unitsAsString), 1)
            ]),
            (_ctx.oType.rooms.listAsText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.mixWB('ROOMS')) + ":", 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.oType.rooms.listAsText), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isResale && !_ctx.oType.options.isRecycable)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.mixWB('NOT_SUITABLE_FOR_RESALE')), 1))
            : _createCommentVNode("", true),
          (_ctx.isResale && _ctx.oType.courseOfAction === 'prevention')
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.mixWB('NOT_FOR_SALE')), 1))
            : _createCommentVNode("", true),
          (_ctx.oType.innerOnlineTypes.length || _ctx.oType.outerOnlineTypes.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 2,
                heading: _ctx.mixWB('ADJOINING_MATERIALS'),
                clearSpacing: true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oType.innerOnlineTypes, (iOType) => {
                    return (_openBlock(), _createBlock(_component_PaneLink, {
                      key: iOType.hashID,
                      class: "PaneLine",
                      "pane-id": iOType.hashID
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaneLineInterConnection, {
                          "online-type": iOType,
                          type: "inner"
                        }, null, 8, ["online-type"])
                      ]),
                      _: 2
                    }, 1032, ["pane-id"]))
                  }), 128)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oType.outerOnlineTypes, (oOType) => {
                    return (_openBlock(), _createBlock(_component_PaneLink, {
                      key: oOType.hashID,
                      class: "PaneLine",
                      "pane-id": oOType.hashID
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaneLineInterConnection, {
                          "online-type": oOType,
                          type: "outer"
                        }, null, 8, ["online-type"])
                      ]),
                      _: 2
                    }, 1032, ["pane-id"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          (!_ctx.oType.courseOfAction || _ctx.oType.courseOfAction === 'demolition')
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 3,
                heading: _ctx.mixWB('WASTE_SORTING')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockDisplayWrap, { items: _ctx.details }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          (_ctx.preventionBox.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 4,
                heading: _ctx.mixWB('PREVENTION')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockDisplayWrap, { items: _ctx.preventionBox }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          (_ctx.reuseBox.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 5,
                heading: _ctx.mixWB('REUSE')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockDisplayWrap, { items: _ctx.reuseBox }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Region, {
            heading: _ctx.mixWB('QUANTITIES')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BlockDisplayWrap, { items: _ctx.quantityItems }, null, 8, ["items"])
            ]),
            _: 1
          }, 8, ["heading"]),
          _createVNode(_component_Region, {
            heading: _ctx.mixWB('CO2_E_SAVINGS')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BlockDisplayWrap, { items: _ctx.co2Items }, null, 8, ["items"])
            ]),
            _: 1
          }, 8, ["heading"]),
          (_ctx.conditionItems.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 6,
                heading: _ctx.mixWB('CONDITION')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockDisplayWrap, { items: _ctx.conditionItems }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Information, {
            title: _ctx.mixWB('COMMENTS_2'),
            texts: _ctx.oType.getTypeComments() || []
          }, null, 8, ["title", "texts"]),
          _createVNode(_component_Information, {
            title: _ctx.mixWB('PROFESSIONAL_ASSESSMENTS'),
            texts: _ctx.informationTexts()
          }, null, 8, ["title", "texts"]),
          _createVNode(_component_Information, {
            title: _ctx.mixWB('WORK_DESCRIPTION'),
            texts: _ctx.oType.getWorkdescriptionTexts() || []
          }, null, 8, ["title", "texts"]),
          (_ctx.oType.samples.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 7,
                heading: _ctx.mixWB('TEST_RESULTS')
              }, {
                default: _withCtx(() => [
                  (_ctx.oType.samples.some(x => x.options.isAalholmSlotDustSample))
                    ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.mixWB('W: Resultatet for prøven foreligger som bilag, særskilt fra dette dokument. Se henvisningen i bemærkninger.')), 1))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.oType.getTestResultsTexts(), (textItem) => {
                          return (_openBlock(), _createElementBlock("p", {
                            key: textItem.text,
                            class: "Paragraph"
                          }, _toDisplayString(textItem.text), 1))
                        }), 128)),
                        _createVNode(_component_TestResultGrid, {
                          samples: _ctx.oType.samples
                        }, null, 8, ["samples"])
                      ], 64))
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          (!_ctx.isResale)
            ? (_openBlock(), _createBlock(_component_FloorPlans, {
                key: 8,
                samples: _ctx.oType.samples,
                clickable: true
              }, null, 8, ["samples"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}