
import PageVideoGuide from '@/components/pageGuide/PageVideoGuide.vue'
import PaneLineRemediation from '@/components/panes/PaneLineRemediation.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneListRemediationTasks',
  computed: {
    ...mapState(currentStore, {
      withCleaning: ({ remediationGroupsByBuilding }) =>
        remediationGroupsByBuilding.withCleaning,
      withoutCleaning: ({ remediationGroupsByBuilding }) =>
        remediationGroupsByBuilding.withoutCleaning,
      potential: ({ remediationGroupsByBuilding }) =>
        remediationGroupsByBuilding.potential,
    }),
  },
  components: { PaneLineRemediation, PageVideoGuide, PaneTitle },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
