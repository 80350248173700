import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14c1425a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneSample CustomScrollbar" }
const _hoisted_2 = { class: "Meta" }
const _hoisted_3 = { class: "Caption" }
const _hoisted_4 = { class: "Caption" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "Paragraph"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_Image = _resolveComponent("Image")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!
  const _component_Region = _resolveComponent("Region")!
  const _component_PaneLineInterConnection = _resolveComponent("PaneLineInterConnection")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _component_Information = _resolveComponent("Information")!
  const _component_TestResultGrid = _resolveComponent("TestResultGrid")!
  const _component_FloorPlans = _resolveComponent("FloorPlans")!
  const _directive_gallery = _resolveDirective("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.sample)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_PaneTitle, { paneID: _ctx.id }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.sample.getFullTitle()), 1)
            ]),
            _: 1
          }, 8, ["paneID"]),
          _withDirectives(_createVNode(_component_CustomSlider, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                return (_openBlock(), _createBlock(_component_Image, {
                  key: image.base.path,
                  image: image,
                  dimensions: { width: 200, height: 200 },
                  transformation: 'square_200',
                  class: "Image_200"
                }, null, 8, ["image"]))
              }), 128))
            ]),
            _: 1
          }, 512), [
            [_directive_gallery, _ctx.id]
          ]),
          _createElementVNode("dl", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.mixWB('STATUS')) + ":", 1),
              _createElementVNode("dd", null, [
                _createElementVNode("span", {
                  class: _normalizeClass(["Label", [_ctx.overallSampleStatus?.className]])
                }, _toDisplayString(_ctx.overallSampleStatus?.text), 3)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.mixWB('UNITS')) + ":", 1),
              _createElementVNode("dd", null, _toDisplayString(_ctx.unitsAsString), 1)
            ])
          ]),
          (_ctx.internalEquivalentSamples.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 0,
                heading: _ctx.mixWB('REPRESENTATIVE_SAMPLE')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('SAMPLE_IS_REPRESENTATIVE_FOR_SEVERAL_BUILDING_PARTS_TEXT')), 1)
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          (_ctx.sample.webID.origin)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 1,
                heading: _ctx.mixWB('REPRESENTATIVE_SAMPLE')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("p", {
                    class: "Paragraph",
                    innerHTML: _ctx.mixWB('BUILDING_PART_REPRESENTED_BY_EXTERNAL_SAMPLE_TEXT')
                  }, null, 8, _hoisted_5),
                  _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('EXTERNAL_SAMPLE')) + ": " + _toDisplayString(_ctx.sample.webID.origin), 1)
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          (_ctx.connectedOTypes.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 2,
                heading: _ctx.mixWB('CONNECTED_TYPES'),
                clearSpacing: true
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.connectedOTypes, (oType) => {
                    return (_openBlock(), _createBlock(_component_PaneLink, {
                      key: oType.hashID,
                      class: "PaneLine",
                      "pane-id": oType.hashID
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaneLineInterConnection, { "online-type": oType }, null, 8, ["online-type"])
                      ]),
                      _: 2
                    }, 1032, ["pane-id"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Information, { texts: _ctx.informationTexts }, null, 8, ["texts"]),
          (_ctx.isShowingTestResults)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 3,
                heading: _ctx.mixWB('TEST_RESULTS')
              }, {
                default: _withCtx(() => [
                  (_ctx.sample.options.isAalholmSlotDustSample)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.mixWB('W: Resultatet for prøven foreligger som bilag, særskilt fra dette dokument. Se henvisningen i bemærkninger.')), 1))
                    : (_openBlock(), _createBlock(_component_TestResultGrid, {
                        key: 1,
                        samples: [_ctx.sample],
                        disableLink: true
                      }, null, 8, ["samples"]))
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          _createVNode(_component_FloorPlans, {
            samples: [_ctx.sample]
          }, null, 8, ["samples"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}