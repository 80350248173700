
import { defineComponent, PropType } from 'vue'
import { union } from 'lodash-es'

export default defineComponent({
  name: 'MarketInquiry',
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    desiredQuantity: {
      type: String,
      required: true,
    },
    desiredPrice: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    delivery: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      textareaLimit: 500,
    }
  },
  methods: {
    updateCheckboxGroup(evt: InputEvent) {
      const target = evt.target as HTMLInputElement

      const selection = this.delivery.includes(target.value)
        ? this.delivery.filter((x) => x !== target.value)
        : union(this.delivery, [target.value])

      this.$emit(`update:delivery`, selection)
    },
  },
})
