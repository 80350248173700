
import Dialog from '@/components/modals/Dialog.vue'
import VideoPlayer from '@/components/VideoPlayer.vue'
import { appStore } from '@/store'
import { mapActions } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PageVideoGuide',
  props: {
    guide: {
      type: String as PropType<
        | 'intro'
        | 'resale'
        | 'fractions'
        | 'remediation'
        | 'units'
        | 'materials'
        | 'samples'
      >,
      required: true,
    },
  },
  computed: {
    text() {
      let selection = [] as { title: boolean; content: string }[]

      switch (this.guide) {
        case 'fractions':
          selection = [
            {
              title: false,
              content: this.mixWB('GUIDE_SLIDE_FRACTION_TEXT_1'),
            },
          ]
          break

        case 'remediation':
          selection = [
            {
              title: false,
              content: this.mixWB('GUIDE_SLIDE_REMEDIATION_TEXT_1'),
            },
          ]
          break

        case 'resale':
          selection = [
            {
              title: false,
              content: this.mixWB('GUIDE_SLIDE_RESALE_TEXT_1'),
            },
          ]
          break

        case 'samples':
          selection = [
            {
              title: false,
              content: this.mixWB('GUIDE_SLIDE_SAMPLES_TEXT_1'),
            },
          ]
          break

        case 'materials':
          selection = [
            {
              title: false,
              content: this.mixWB('GUIDE_SLIDE_TYPES_TEXT_1')
                .replace(/!\*\*/g, '</strong>')
                .replace(/\*\*/g, '<strong>'),
            },
          ]

          break
      }

      return selection
    },
  },
  methods: {
    ...mapActions(appStore, ['setShownDialog']),
  },
  components: { VideoPlayer, Dialog },
})
