
import Accordion from '@/components/Accordion.vue'
import { defineComponent, PropType } from 'vue'

export interface IBlockItem {
  title: string
  text: string | { title: string; text: string }[]
  isFullWidth: boolean
  isBold?: boolean
  extraTexts: string[]
  footNotes?: {
    pretext: string
    link: {
      href: string
      text: string
    }
  }[]
}

export default defineComponent({
  name: 'BlockDisplayBlock',
  props: {
    item: {
      type: Object as PropType<IBlockItem>,
      required: true,
    },
  },
  data() {
    return {
      isAccordionOpen: false,
    }
  },
  computed: {
    rootClasses() {
      return {
        IsFullWidth: this.item.isFullWidth,
        IsAccordion: this.hasAccordion,
        IsDefault: !this.hasAccordion,
      }
    },
    hasAccordion() {
      return !!this.item.extraTexts.length
    },
  },
  components: { Accordion },
})
