
import versionKeys from '@/globals/javascript/constants/versionKeys'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { currentStore } from '@/store'
import { compare } from 'compare-versions'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'TestResultGridSampleColumn',
  props: {
    sample: {
      type: Object as PropType<ProSample>,
      required: true,
    },
    disableLink: {
      type: Boolean,
      default: false,
    },
    isLastColumn: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      sampleType: this.sample.sampleType,
      isInternalEquivalent: this.sample.options.isInternalEquivalent,
    }
  },
  computed: {
    ...mapState(currentStore, ['samples', 'projectProVersion']),
    fullSampleTitle() {
      // VERSION CHECK: Same or newer than 2.8.4
      if (
        compare(
          this.projectProVersion,
          versionKeys.HIDE_EQUIVALENT_NUMBERS,
          '>='
        )
      ) {
        const sampleToUse = this.sample.getReferenceSample()

        let title = sampleToUse.sampleTypeID

        if (sampleToUse.webID.origin) {
          title += ` - ${sampleToUse.webID.origin}`
        }

        title += ` - ${sampleToUse.getCombinedTypeTitle()}`

        return title
      }

      // VERSION CHECK: Same or older than 2.8.4
      else {
        let title = this.sample.sampleTypeID

        if (this.sample.webID.origin) {
          title += ` - ${this.sample.webID.origin}`
        }

        if (this.isInternalEquivalent) {
          title += ` (P${this.sample.equivalentSampleNumber})`
        }

        title += ` - ${this.sample.getCombinedTypeTitle()}`

        return title
      }
    },
    linkedSampleID() {
      if (this.isInternalEquivalent) {
        const referenceSample =
          this.samples.standard.find(
            (x) => x.id === this.sample.equivalentSampleID
          ) ?? null

        return !referenceSample ? '' : referenceSample.hashID
      }

      return this.disableLink ? '' : this.sample.hashID
    },
    kindOfSampleText() {
      return this.sample.getKindOfSampleText()
    },
    analysisTestListResults() {
      return this.sample.getAnalysisTestListResults()
    },
  },
})
