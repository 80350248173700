
import Image from '@/components/Image.vue'
import { IFractionGroup } from '@/__types__/fractionGroup'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaneLineFraction',
  props: {
    fraction: {
      type: Object as PropType<IFractionGroup>,
      required: true,
    },
    inResaleContext: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      image: this.fraction.oTypes?.[0]?.types?.[0].images[0],
    }
  },
  components: { Image },
})
