
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneTitle',
  props: {
    paneID: {
      type: String,
      default: 'root',
    },
  },
  data() {
    return {
      href: this.getHref(),
      showBackLink: this.paneID !== 'root',
    }
  },
  methods: {
    getHref() {
      const { name, params } = this.$route

      if (this.paneID === 'child') {
        return this.$router.resolve({ name: name as string }).href
      }

      const paneIDs =
        typeof params.paneIDs === 'string'
          ? [params.paneIDs]
          : params.paneIDs ?? []

      const index = paneIDs.findIndex((x) => x === this.paneID)

      if (index === -1) {
        this.showBackLink = false
        return ''
      }

      const { href } = this.$router.resolve({
        name: name as string,
        params: {
          ...params,
          paneIDs: paneIDs.slice(0, index),
        },
      })

      return href
    },
  },
})
