import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e16912d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MarketInquiry" }
const _hoisted_2 = { class: "FormRow Title" }
const _hoisted_3 = { class: "Heading1" }
const _hoisted_4 = { class: "FormRow" }
const _hoisted_5 = { class: "FormInput Required" }
const _hoisted_6 = { for: "name" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { class: "FormInput Required" }
const _hoisted_9 = { for: "phone" }
const _hoisted_10 = ["value"]
const _hoisted_11 = { class: "FormRow" }
const _hoisted_12 = { class: "FormInput Required" }
const _hoisted_13 = { for: "email" }
const _hoisted_14 = ["value"]
const _hoisted_15 = { class: "FormRow" }
const _hoisted_16 = { class: "FormInput CheckboxGroup Required" }
const _hoisted_17 = { class: "Checkbox" }
const _hoisted_18 = { for: "selfDismantling" }
const _hoisted_19 = { class: "Checkbox" }
const _hoisted_20 = { for: "selfPickup" }
const _hoisted_21 = { class: "Checkbox" }
const _hoisted_22 = { for: "isDelivered" }
const _hoisted_23 = { class: "FormRow" }
const _hoisted_24 = { class: "FormInput" }
const _hoisted_25 = { for: "desiredQuantity" }
const _hoisted_26 = ["placeholder", "value"]
const _hoisted_27 = { class: "FormRow" }
const _hoisted_28 = { class: "FormInput" }
const _hoisted_29 = { for: "desiredPrice" }
const _hoisted_30 = ["placeholder", "value"]
const _hoisted_31 = { class: "FormRow" }
const _hoisted_32 = { class: "FormInput Textarea" }
const _hoisted_33 = { for: "message" }
const _hoisted_34 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.mixWB('MAKE_AN_OFFER')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('MARKET_INQUIRY_FORM_INTRO_1')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('MARKET_INQUIRY_FORM_INTRO_2')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.mixWB('NAME')) + " *", 1),
        _createElementVNode("input", {
          id: "name",
          name: "name",
          type: "text",
          value: _ctx.name,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:name', $event.target.value.trim()))),
          required: ""
        }, null, 40, _hoisted_7)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.mixWB('PHONE')) + " *", 1),
        _createElementVNode("input", {
          id: "phone",
          name: "phone",
          type: "tel",
          value: _ctx.phone,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:phone', $event.target.value.trim()))),
          required: ""
        }, null, 40, _hoisted_10)
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.mixWB('EMAIL')) + " *", 1),
        _createElementVNode("input", {
          id: "email",
          name: "email",
          type: "email",
          value: _ctx.email,
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:email', $event.target.value.trim()))),
          required: ""
        }, null, 40, _hoisted_14)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('SELECT_ONE_OR_MORE')) + " *", 1),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("input", {
            id: "selfDismantling",
            name: "delivery",
            type: "checkbox",
            value: "self-dismantling",
            onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateCheckboxGroup && _ctx.updateCheckboxGroup(...args)))
          }, null, 32),
          _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.mixWB('CAN_TAKE_CARE_OF_DISASSEMBLY')), 1)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("input", {
            id: "selfPickup",
            name: "delivery",
            type: "checkbox",
            value: "self-pickup",
            onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updateCheckboxGroup && _ctx.updateCheckboxGroup(...args)))
          }, null, 32),
          _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.mixWB('CAN_PICK_UP_AT_THE_ADDRESS')), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("input", {
            id: "isDelivered",
            name: "delivery",
            type: "checkbox",
            value: "want-delivery",
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.updateCheckboxGroup && _ctx.updateCheckboxGroup(...args)))
          }, null, 32),
          _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.mixWB('DELIVERY_TO_MY_ADDRESS')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_23, [
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.mixWB('DESIRED_QUANTITY')), 1),
        _createElementVNode("input", {
          id: "desiredQuantity",
          name: "desiredQuantity",
          type: "text",
          placeholder: _ctx.mixWB('DESIRED_QUANTITY_PLACEHOLDER_TEXT'),
          value: _ctx.desiredQuantity,
          onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:desiredQuantity', $event.target.value.trim())))
        }, null, 40, _hoisted_26)
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.mixWB('EXPECTED_PRICE_RANGE')), 1),
        _createElementVNode("input", {
          id: "desiredPrice",
          name: "desiredPrice",
          type: "text",
          placeholder: _ctx.mixWB('EXPECTED_PRICE_RANGE_PLACEHOLDER_TEXT'),
          value: _ctx.desiredPrice,
          onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:desiredPrice', $event.target.value.trim())))
        }, null, 40, _hoisted_30)
      ])
    ]),
    _createElementVNode("div", _hoisted_31, [
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.mixWB('MESSAGE')), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.message.length) + " / " + _toDisplayString(_ctx.textareaLimit), 1),
        _createElementVNode("textarea", {
          id: "message",
          name: "message",
          value: _ctx.message,
          placeholder: _ctx.mixWB('PLACEHOLDER_ADDITIONAL_INFORMATION'),
          onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:message', $event.target.value.trim().substr(0, _ctx.textareaLimit)))),
          maxlength: "500"
        }, null, 40, _hoisted_34)
      ])
    ])
  ]))
}