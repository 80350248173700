import { resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["PaneContainer", {
      'AllowTransition': _ctx.isMounted,
      'FullSize': _ctx.paneIDs.length === 0 || _ctx.supportedPaneCount === 1
    }]),
    style: _normalizeStyle(_ctx.paneRootMaxWidth)
  }, [
    _createVNode(_TransitionGroup, {
      tag: "div",
      class: _normalizeClass(["PaneSlider", {'AllowTransition': _ctx.isMounted}]),
      style: _normalizeStyle(_ctx.paneSliderTransition)
    }, {
      default: _withCtx(() => [
        (_ctx.firstPane)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.firstPane), {
              key: "static",
              class: _normalizeClass(["Pane js-pane-page", {
          'FullSize': _ctx.paneIDs.length === 0 || _ctx.supportedPaneCount === 1,
          'AllowTransition': _ctx.isMounted
        }]),
              "data-pane-index": -1,
              style: _normalizeStyle(_ctx.paneWidth),
              "onHook:mounted": _ctx.allowTransitions
            }, null, 8, ["class", "style", "onHook:mounted"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paneIDs, (paneID, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: paneID }, [
            (_ctx.getPane(paneID))
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getPane(paneID)), {
                  key: paneID,
                  class: _normalizeClass(["Pane js-pane-page", {'FullSize': _ctx.supportedPaneCount === 1, 'AllowTransition': _ctx.isMounted}]),
                  id: paneID,
                  "data-pane-id": paneID,
                  "data-pane-index": index,
                  onDataNotFound: ($event: any) => (_ctx.stopAtPreviousPane(index)),
                  style: _normalizeStyle(_ctx.paneWidth)
                }, null, 8, ["class", "id", "data-pane-id", "data-pane-index", "onDataNotFound", "style"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }, 8, ["class", "style"])
  ], 6))
}