import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86f09c98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneResale CustomScrollbar" }
const _hoisted_2 = { class: "PaneIntro" }
const _hoisted_3 = {
  key: 0,
  class: "List"
}
const _hoisted_4 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_5 = { class: "Label" }
const _hoisted_6 = { class: "PaneEmptyText" }
const _hoisted_7 = {
  key: 1,
  class: "List"
}
const _hoisted_8 = { class: "FeaturedTitleLine" }
const _hoisted_9 = { class: "Label" }
const _hoisted_10 = {
  key: 2,
  class: "List"
}
const _hoisted_11 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_12 = { class: "Label" }
const _hoisted_13 = { class: "ListContent" }
const _hoisted_14 = {
  key: 3,
  class: "List"
}
const _hoisted_15 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_16 = { class: "Label" }
const _hoisted_17 = { class: "ListContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_PageVideoGuide = _resolveComponent("PageVideoGuide")!
  const _component_FeaturedOnlineType = _resolveComponent("FeaturedOnlineType")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!
  const _component_PaneLineBuildingPart = _resolveComponent("PaneLineBuildingPart")!
  const _component_PaneLineFraction = _resolveComponent("PaneLineFraction")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PaneTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.mixWB('RESALE')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('OVERVIEW_OF_ALL_RESALABLE_RESOURCES_IN_THE_PROJECT')), 1),
      _createVNode(_component_PageVideoGuide, { guide: "resale" })
    ]),
    (!_ctx.resale.all.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.mixWB('RESALE_ITEMS')), 1)
          ]),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.mixWB('NO_RESALE_ITEMS_FOUND')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.resale.highlighted.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.mixWB('FEATURED_RESALE_ITEMS')), 1)
          ]),
          _createVNode(_component_CustomSlider, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resale.highlighted, (oType) => {
                return (_openBlock(), _createBlock(_component_PaneLink, {
                  key: oType.resaleID,
                  "pane-id": oType.resaleID
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FeaturedOnlineType, { "online-type": oType }, null, 8, ["online-type"])
                  ]),
                  _: 2
                }, 1032, ["pane-id"]))
              }), 128))
            ]),
            _: 1
          }),
          (_ctx.resale.highlighted.length > 2)
            ? (_openBlock(), _createBlock(_component_PaneLink, {
                key: 0,
                class: "FeaturedLink",
                "pane-id": 'featured'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.mixWB('VIEW_ALL_FETURED_RESALE_ITEMS')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.resale.categories.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.mixWB('BUILDING_PARTS')), 1)
          ]),
          _createElementVNode("div", _hoisted_13, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resale.categories, (buildingPart) => {
              return (_openBlock(), _createBlock(_component_PaneLink, {
                key: buildingPart.hashID,
                class: "PaneLine CategoryLine",
                "pane-id": buildingPart.hashID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PaneLineBuildingPart, {
                    buildingPart: buildingPart,
                    inResaleContext: true
                  }, null, 8, ["buildingPart"])
                ]),
                _: 2
              }, 1032, ["pane-id"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.resale.fractions.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.mixWB('FRACTIONS')), 1)
          ]),
          _createElementVNode("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resale.fractions, (fraction) => {
              return (_openBlock(), _createBlock(_component_PaneLink, {
                key: fraction.hashID,
                class: "PaneLine FractionLine",
                "pane-id": fraction.hashID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PaneLineFraction, {
                    fraction: fraction,
                    inResaleContext: true
                  }, null, 8, ["fraction"])
                ]),
                _: 2
              }, 1032, ["pane-id"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}