
import FeaturedOnlineType from '@/components/FeaturedOnlineType.vue'
import PaneLineOnlineType from '@/components/panes/PaneLineOnlineType.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { appStore, currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneBuildingPart',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(appStore, ['isResale']),
    ...mapState(currentStore, ['categoryGroupsByBuilding']),
    buildingPartGroup() {
      const buildingPartGroups = this.isResale
        ? this.categoryGroupsByBuilding.resale
        : this.categoryGroupsByBuilding.all

      return buildingPartGroups.find((x) => x.hashID === this.id)
    },
    onlineTypes() {
      return this.buildingPartGroup?.onlineTypes.allRelated ?? []
    },
    title() {
      return this.buildingPartGroup?.title ?? ''
    },
  },
  components: { PaneLineOnlineType, PaneTitle, FeaturedOnlineType },
  created() {
    // If no items - go back to root of current flow
    if (!this.onlineTypes.length) {
      this.$router.replace({ name: this.$route.name as string })
    }
  },
})
