import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7255d6a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneListFractions CustomScrollbar" }
const _hoisted_2 = { class: "PaneIntro" }
const _hoisted_3 = { class: "List" }
const _hoisted_4 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_5 = { class: "Label" }
const _hoisted_6 = {
  key: 0,
  class: "PaneEmptyText"
}
const _hoisted_7 = {
  key: 1,
  class: "ListContent"
}
const _hoisted_8 = {
  key: 0,
  class: "List"
}
const _hoisted_9 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_10 = { class: "Label" }
const _hoisted_11 = { class: "ListContent" }
const _hoisted_12 = {
  key: 1,
  class: "List"
}
const _hoisted_13 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_14 = { class: "Label" }
const _hoisted_15 = { class: "ListContent" }
const _hoisted_16 = {
  key: 2,
  class: "List"
}
const _hoisted_17 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_18 = { class: "Label" }
const _hoisted_19 = { class: "ListContent" }
const _hoisted_20 = {
  key: 3,
  class: "List"
}
const _hoisted_21 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_22 = { class: "Label" }
const _hoisted_23 = { class: "ListContent" }
const _hoisted_24 = {
  key: 4,
  class: "List"
}
const _hoisted_25 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_26 = { class: "Label" }
const _hoisted_27 = { class: "ListContent" }
const _hoisted_28 = {
  key: 5,
  class: "Note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_PageVideoGuide = _resolveComponent("PageVideoGuide")!
  const _component_PaneLineFraction = _resolveComponent("PaneLineFraction")!
  const _component_PaneLink = _resolveComponent("PaneLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PaneTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.mixWB('FRACTIONS')) + " (" + _toDisplayString(_ctx.allFractions.length) + ")", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('FRACTIONS_PAGE_INTRO_TEXT_1')), 1),
      _createVNode(_component_PageVideoGuide, { guide: "fractions" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.mixWB('UNKNOWN')), 1)
      ]),
      (!_ctx.unknownFraction.oTypes.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.mixWB('NO_TYPES_REGISTERED')), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_PaneLink, {
              class: "PaneLine",
              "pane-id": _ctx.unknownFraction.hashID
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PaneLineFraction, { fraction: _ctx.unknownFraction }, null, 8, ["fraction"])
              ]),
              _: 1
            }, 8, ["pane-id"])
          ]))
    ]),
    (_ctx.preventionFraction.oTypes.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.mixWB('PREVENTION')), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_PaneLink, {
              class: "PaneLine",
              "pane-id": _ctx.preventionFraction.hashID
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PaneLineFraction, { fraction: _ctx.preventionFraction }, null, 8, ["fraction"])
              ]),
              _: 1
            }, 8, ["pane-id"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.reuseFraction.oTypes.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.mixWB('REUSE')), 1)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_PaneLink, {
              class: "PaneLine",
              "pane-id": _ctx.reuseFraction.hashID
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PaneLineFraction, { fraction: _ctx.reuseFraction }, null, 8, ["fraction"])
              ]),
              _: 1
            }, 8, ["pane-id"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.recycleFractions.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.mixWB('RECYCLING')), 1)
          ]),
          _createElementVNode("div", _hoisted_19, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recycleFractions, (fraction) => {
              return (_openBlock(), _createBlock(_component_PaneLink, {
                key: fraction.hashID,
                class: "PaneLine",
                "pane-id": fraction.hashID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PaneLineFraction, { fraction: fraction }, null, 8, ["fraction"])
                ]),
                _: 2
              }, 1032, ["pane-id"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.utilizationFractions.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.mixWB('UTILISATION')), 1)
          ]),
          _createElementVNode("div", _hoisted_23, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.utilizationFractions, (fraction) => {
              return (_openBlock(), _createBlock(_component_PaneLink, {
                key: fraction.hashID,
                class: "PaneLine",
                "pane-id": fraction.hashID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PaneLineFraction, { fraction: fraction }, null, 8, ["fraction"])
                ]),
                _: 2
              }, 1032, ["pane-id"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.disposalFractions.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.mixWB('DISPOSAL')), 1)
          ]),
          _createElementVNode("div", _hoisted_27, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.disposalFractions, (fraction) => {
              return (_openBlock(), _createBlock(_component_PaneLink, {
                key: fraction.hashID,
                class: "PaneLine",
                "pane-id": fraction.hashID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PaneLineFraction, { fraction: fraction }, null, 8, ["fraction"])
                ]),
                _: 2
              }, 1032, ["pane-id"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.allFractions.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_28, "* " + _toDisplayString(_ctx.mixWB('MISSING_CALCULATIONS')), 1))
      : _createCommentVNode("", true)
  ]))
}