
import CustomSlider from '@/components/CustomSlider.vue'
import FeaturedOnlineType from '@/components/FeaturedOnlineType.vue'
import PageVideoGuide from '@/components/pageGuide/PageVideoGuide.vue'
import PaneLineBuildingPart from '@/components/panes/PaneLineBuildingPart.vue'
import PaneLineFraction from '@/components/panes/PaneLineFraction.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneResale',
  computed: {
    ...mapState(currentStore, {
      resale: 'resaleByBuilding',
    }),
  },
  components: {
    PaneLineBuildingPart,
    PaneLineFraction,
    PageVideoGuide,
    FeaturedOnlineType,
    CustomSlider,
    PaneTitle,
  },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
