
import Image from '@/components/Image.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'FeaturedOnlineType',
  props: {
    onlineType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
  },
  computed: {
    image() {
      return this.onlineType.types[0].images[0]
    },
    showAmountText() {
      return !!this.onlineType.amounts.totalTons
    },
    amountText() {
      return this.onlineType.getTonsTextWithDetails()
    },
    information() {
      const { index, titles } = this.onlineType
      const title = `${index.primary}.${index.secondary} ${titles.category}`

      return {
        title,
        material: titles.types,
      }
    },
  },
  components: { Image },
})
