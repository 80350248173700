
import FloorPlans from '@/components/FloorPlans.vue'
import PaneLineInterConnection from '@/components/panes/PaneLineInterConnection.vue'
import Information from '@/components/partials/Information.vue'
import TestResultGrid from '@/components/partials/TestResultGrid.vue'
import Region from '@/components/Region.vue'
import { currentStore, proProjectsStore } from '@/store'
import { IInfoText } from '@/__types__/utils/infoText'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { union } from 'lodash-es'
import CustomSlider from '@/components/CustomSlider.vue'
import Image from '@/components/Image.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { IImage } from '@/__types__/_pro/partials/image'

export default defineComponent({
  name: 'PaneSample',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(currentStore, ['units', 'onlineTypes', 'samples']),
    ...mapState(proProjectsStore, ['allSamples']),
    sample() {
      return this.samples.all.find((s) => s.hashID === this.id) ?? null
    },
    internalEquivalentSamples() {
      if (!this.sample?.sampleNumber) {
        return []
      }

      return this.samples.equivalent.filter(
        (x) =>
          x.equivalentSampleNumber === this.sample?.sampleNumber &&
          x.options.isInternalEquivalent
      )
    },
    overallSampleStatus() {
      return this.sample?.getStatus() ?? null
    },
    oType() {
      return this.sample?.getOnlineType() ?? null
    },
    sampledType() {
      return this.sample?.getSampledType() ?? null
    },
    unitsAsString() {
      if (!this.sampledType) {
        return ''
      }

      const unitIDs = this.sampledType.unitIDs ?? []

      if (!unitIDs.length) {
        return ''
      }

      return unitIDs
        .map(
          (id) =>
            this.units.all.find((u) => u.id === id)?.getFullTitle() ?? null
        )
        .filter(Boolean)
        .join(', ')
    },
    images() {
      if (!this.sample) {
        return []
      }

      // If sample is from other project - get images from original sample
      if (this.sample.otherScreeningID) {
        const sample = this.allSamples.find(
          (s) => s.id === this.sample?.equivalentSampleID
        )

        const { away, close } = sample?.images ?? { away: null, close: null }

        return [close, away].filter(Boolean) as IImage[]
      }

      // Use current samples images
      const { away, close } = this.sample.images

      return [close, away].filter(Boolean)
    },
    connectedOTypes() {
      let oTypes: OnlineType[] = []

      if (!this.sample) {
        return oTypes
      }

      const allSamples = [this.sample, ...this.internalEquivalentSamples]

      allSamples.forEach((sample) => {
        // For material sample
        if (sample.kindOfSample === 'material') {
          const foundOTypes = this.onlineTypes.filter((oType) => {
            return oType.typeIDs.find(
              (typeID) =>
                typeID.includes(sample?.typeID || '') &&
                !typeID.includes('coating-')
            )
          })

          oTypes = union(oTypes, foundOTypes)
        }

        // For coating sample
        if (sample.kindOfSample === 'coating') {
          const foundOTypes = this.onlineTypes.filter((oType) => {
            return oType.samples.find((x) => x.id === sample?.id)
          })

          oTypes = union(oTypes, foundOTypes)
        }
      })

      return oTypes
    },
    informationTexts() {
      if (!this.sample) {
        return []
      }

      const infoTexts: IInfoText[] = []

      // Sample is Postponed
      if (this.sample.sampleType === 'postponed') {
        const testNames = this.sample.getTestList().map((x) => x.text)

        infoTexts.push({
          type: 'header',
          text: this.mixWB('POSTPONED_SAMPLE'),
        })
        infoTexts.push({
          text: this.mixWB('SAMPLING_WAS_NOT_POSSIBLE_DURING_THE_MAPPING'),
        })
        infoTexts.push({
          text: `${this.mixWB('ASSESSED_RISK_OF')}: ${testNames.join(', ')}.`,
        })
        infoTexts.push({
          text: this.mixWB('POSTPONED_SAMPLE_INFO_TEXT'),
        })

        return infoTexts
      }

      return []
    },
    isShowingTestResults() {
      return !!this.sample
    },
  },
  components: {
    Region,
    TestResultGrid,
    PaneLineInterConnection,
    FloorPlans,
    Information,
    CustomSlider,
    Image,
    PaneTitle,
  },
  created() {
    if (!this.sample) {
      this.$emit('data-not-found')
    }
  },
})
