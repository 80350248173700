import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c4d6362"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneUnit CustomScrollbar" }
const _hoisted_2 = {
  key: 0,
  class: "Meta"
}
const _hoisted_3 = { class: "Legend" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "Legend" }
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_Image = _resolveComponent("Image")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!
  const _component_BlockDisplayWrap = _resolveComponent("BlockDisplayWrap")!
  const _component_Region = _resolveComponent("Region")!
  const _component_FloorPlans = _resolveComponent("FloorPlans")!
  const _component_PaneLineInterConnection = _resolveComponent("PaneLineInterConnection")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _directive_gallery = _resolveDirective("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PaneTitle, { paneID: _ctx.id }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.unit?.getFullTitle()), 1)
      ]),
      _: 1
    }, 8, ["paneID"]),
    _withDirectives(_createVNode(_component_CustomSlider, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
          return (_openBlock(), _createBlock(_component_Image, {
            key: image.base.path,
            image: image,
            dimensions: { width: 200, height: 200 },
            transformation: 'square_200',
            class: "Image_200"
          }, null, 8, ["image"]))
        }), 128))
      ]),
      _: 1
    }, 512), [
      [_directive_gallery, _ctx.id]
    ]),
    (_ctx.unit)
      ? (_openBlock(), _createElementBlock("dl", _hoisted_2, [
          _createElementVNode("div", null, [
            _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.mixWB('ADDRESS')) + ":", 1),
            _createElementVNode("dd", null, _toDisplayString(_ctx.projectAddress), 1)
          ]),
          (_ctx.unitTypeText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("dt", _hoisted_5, _toDisplayString(_ctx.mixWB('TYPE_OF_BUILDING')) + ":", 1),
                _createElementVNode("dd", null, _toDisplayString(_ctx.unitTypeText), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isBuilding)
      ? (_openBlock(), _createBlock(_component_Region, {
          key: 1,
          heading: _ctx.mixWB('INFORMATION_ABOUT_THE_BUILDING')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BlockDisplayWrap, { items: _ctx.details }, null, 8, ["items"])
          ]),
          _: 1
        }, 8, ["heading"]))
      : _createCommentVNode("", true),
    (_ctx.unit)
      ? (_openBlock(), _createBlock(_component_FloorPlans, {
          key: 2,
          unit: _ctx.unit,
          clickable: true
        }, null, 8, ["unit"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Region, {
      heading: _ctx.mixWB('REGISTERED_TYPES'),
      clearSpacing: !!_ctx.relatedOnlineTypes.length
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedOnlineTypes, (OType) => {
          return (_openBlock(), _createBlock(_component_PaneLink, {
            key: OType.hashID,
            class: "PaneLine",
            "pane-id": OType.hashID
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PaneLineInterConnection, { "online-type": OType }, null, 8, ["online-type"])
            ]),
            _: 2
          }, 1032, ["pane-id"]))
        }), 128)),
        (_ctx.relatedOnlineTypes.length === 0)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.mixWB('THIS_UNIT_HAS_NO_REGISTERED_TYPES')), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["heading", "clearSpacing"])
  ]))
}