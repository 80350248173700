import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5133dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "HeadLine"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Region = _resolveComponent("Region")!

  return (_ctx.texts.length)
    ? (_openBlock(), _createBlock(_component_Region, {
        key: 0,
        class: "Information",
        heading: _ctx.heading
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.texts, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.text
            }, [
              (item.type === 'header')
                ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(item.text), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(item.text), 1))
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["heading"]))
    : _createCommentVNode("", true)
}