
import { debounce } from 'lodash-es'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'CustomSlider',
  data() {
    const debouncedSliderResizeWatch = debounce(() => {
      this.resetSlider()
    }, 50)

    return {
      sliderWidth: 0,
      noOfItems: 0,
      itemWidth: 0,
      gapSize: 12,
      itemsPerSlide: 0,
      totalSlides: 0,
      isAllItemsVisible: true,
      activeSlide: 1,
      debouncedSliderResizeWatch,
      lastSliderTranslationPos: 0,

      // Dragging
      grabbing: false,
      dragging: false,
      dragStart: {
        x: 0,
        y: 0,
      },
      dragEnd: {
        x: 0,
        y: 0,
      },
    }
  },
  methods: {
    onLeftClick() {
      this.slideLeft()
    },
    onRightClick() {
      this.slideRight()
    },
    onSliderResize() {
      this.debouncedSliderResizeWatch()
    },
    onTouchStartListener(event: MouseEvent | TouchEvent) {
      if (!event || this.totalSlides === 0) return

      this.grabbing = true
      this.dragStart = {
        x: 'clientX' in event ? event.clientX : event.touches[0].clientX,
        y: 'clientY' in event ? event.clientY : event.touches[0].clientY,
      }
    },
    onTouchMoveListener(event: MouseEvent | TouchEvent) {
      if (!event || !this.grabbing) {
        return
      }

      this.dragEnd = {
        x: 'clientX' in event ? event.clientX : event.touches[0].clientX,
        y: 'clientY' in event ? event.clientY : event.touches[0].clientY,
      }

      const diff = this.dragStart.x - this.dragEnd.x

      if (Math.abs(diff) > 10) {
        this.dragging = true
      }

      const xMovement = Math.abs(this.dragStart.x - this.dragEnd.x)
      const yMovement = Math.abs(this.dragStart.y - this.dragEnd.y)

      if (xMovement > yMovement && this.totalSlides > 0) {
        event.preventDefault()

        const newSliderTranslationPos = this.lastSliderTranslationPos - diff

        const sliderElement = this.$refs.Slider as HTMLBaseElement

        if (!sliderElement?.style) {
          return
        }
        sliderElement.style.webkitTransform = `translate3d(${newSliderTranslationPos}px, 0, 0)`
        sliderElement.style.transform = `translate3d(${newSliderTranslationPos}px, 0, 0)`
      }
    },
    onTouchEndListener() {
      this.grabbing = false

      if (!this.dragging) {
        this.dragStart = {
          x: this.dragEnd.x,
          y: this.dragEnd.y,
        }
        return
      }

      const xMovement = Math.abs(this.dragStart.x - this.dragEnd.x)
      const yMovement = Math.abs(this.dragStart.y - this.dragEnd.y)

      const diff = this.dragStart.x - this.dragEnd.x

      if (xMovement < yMovement) {
        this.showActiveSlide()
        return
      }

      if (Math.abs(diff) > 10) {
        requestAnimationFrame(() => {
          if (diff < 0) {
            if (this.activeSlide <= 1) {
              this.activeSlide = 1
            } else {
              this.activeSlide -= 1
            }
          }
          if (diff > 0) {
            if (this.activeSlide >= this.totalSlides) {
              this.activeSlide = this.totalSlides
            } else {
              this.activeSlide += 1
            }
          }
          this.dragging = false
          this.showActiveSlide()
        })
      }
    },
    slideLeft() {
      this.setSliderData()

      if (this.isAllItemsVisible) {
        return
      }

      if (this.activeSlide === 1) {
        return
      }

      this.activeSlide -= 1

      this.showActiveSlide()
    },
    slideRight() {
      this.setSliderData()

      if (this.isAllItemsVisible) {
        return
      }

      if (this.activeSlide === this.totalSlides) {
        return
      }

      this.activeSlide += 1

      this.showActiveSlide()
    },
    showActiveSlide() {
      let slideTranslationPos = 0

      // Check to show last slide
      if (this.activeSlide === this.totalSlides) {
        const fullWidth =
          this.noOfItems * (this.itemWidth + this.gapSize) - this.gapSize

        slideTranslationPos = (fullWidth - this.sliderWidth) * -1
      }
      // Inbetween first and last
      else if (this.activeSlide > 1) {
        slideTranslationPos =
          (this.activeSlide - 1) *
          this.itemsPerSlide *
          (this.itemWidth + this.gapSize) *
          -1
      }

      // Slide the slider
      const sliderElement = this.$refs.Slider as HTMLBaseElement

      if (!sliderElement?.style) {
        return
      }
      sliderElement.style.webkitTransform = `translate3d(${slideTranslationPos}px, 0, 0)`
      sliderElement.style.transform = `translate3d(${slideTranslationPos}px, 0, 0)`

      this.lastSliderTranslationPos = slideTranslationPos
    },
    setSliderData() {
      const customSliderElement = this.$refs.CustomSlider as HTMLBaseElement
      const sliderElement = this.$refs.Slider as HTMLBaseElement

      if (!customSliderElement || !sliderElement) {
        return
      }

      const sliderItems = sliderElement.children as HTMLCollection
      const firstItem = sliderItems[0]

      if (!firstItem) {
        return
      }

      // Get slider width
      this.sliderWidth = customSliderElement.getBoundingClientRect().width

      // Get number of items
      this.noOfItems = sliderItems.length

      // Get width of first item
      this.itemWidth = firstItem.getBoundingClientRect().width

      // Check if all items are visible
      const totalItemsWidth =
        this.noOfItems * (this.itemWidth + this.gapSize) - this.gapSize

      if (totalItemsWidth < this.sliderWidth) {
        this.isAllItemsVisible = true
        return
      }

      this.isAllItemsVisible = false

      // Get items per slide
      this.itemsPerSlide = Math.floor(
        this.sliderWidth / (this.itemWidth + this.gapSize)
      )

      // Get total slides
      this.totalSlides = Math.ceil(this.noOfItems / this.itemsPerSlide)
    },
    resetSlider() {
      if (!this.noOfItems) {
        return
      }

      // Get old data
      const oldTotalSlides = this.totalSlides
      const oldActiveSlide = this.activeSlide

      // Get new data
      this.setSliderData()

      // Compare old and new data and take action
      if (this.isAllItemsVisible || oldActiveSlide === 1) {
        this.activeSlide = 1
        this.showActiveSlide()
        return
      }

      // Check if slide needs to stick/snap to right side of last item
      const fewerSlidesThanBefore = oldActiveSlide >= this.totalSlides
      const lastSlideWasActive = oldActiveSlide === oldTotalSlides

      if (fewerSlidesThanBefore || lastSlideWasActive) {
        this.activeSlide = this.totalSlides
        this.showActiveSlide()
        return
      }
    },
  },
  mounted() {
    this.setSliderData()

    // Set up observers
    const customSliderElement = this.$refs.CustomSlider as HTMLBaseElement
    new ResizeObserver(this.onSliderResize).observe(customSliderElement)

    // Set up event listeners
    // - Dragging events mouse
    customSliderElement.addEventListener('mousedown', this.onTouchStartListener)
    customSliderElement.addEventListener('mousemove', this.onTouchMoveListener)
    customSliderElement.addEventListener('mouseleave', this.onTouchEndListener)

    // - Dragging events touch
    customSliderElement.addEventListener(
      'touchstart',
      this.onTouchStartListener,
      { passive: false }
    )
    customSliderElement.addEventListener(
      'touchmove',
      this.onTouchMoveListener,
      { passive: false }
    )
    customSliderElement.addEventListener('touchend', this.onTouchEndListener, {
      passive: false,
    })

    // - Dragging window events
    window.addEventListener('mouseup', this.onTouchEndListener)
  },
  beforeUnmount() {
    this.debouncedSliderResizeWatch.cancel()
    window.removeEventListener('mouseup', this.onTouchEndListener)
  },
})
