import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d1ecf4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "Container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _component_Region = _resolveComponent("Region")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.floorplans, (floorplan) => {
    return (_openBlock(), _createBlock(_component_Region, {
      key: floorplan.id,
      class: "FloorPlan",
      heading: _ctx.mixWB('FLOOR_PLAN') + ': ' + floorplan.unit.getFullTitle()
    }, {
      default: _withCtx(() => [
        (_ctx.unit && !_ctx.unit.getFloorPlan().length)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.mixWB('THIS_UNIT_HAS_NO_FLOOR_PLAN_YET')), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Image, {
                image: floorplan.floorPlan,
                transformation: 'default',
                onLoaded: ($event: any) => (_ctx.floorPlanLoaded(floorplan.id))
              }, null, 8, ["image", "onLoaded"]),
              (_ctx.floorplansReady.includes(floorplan.id))
                ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(floorplan.dots, (dot) => {
                    return (_openBlock(), _createBlock(_component_PaneLink, {
                      key: dot.hashID,
                      "pane-id": _ctx.clickable ? dot.hashID : '',
                      class: _normalizeClass(["Dot", dot.class]),
                      style: _normalizeStyle(_ctx.getDotPositionStyle(dot.position))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(dot.text), 1)
                      ]),
                      _: 2
                    }, 1032, ["pane-id", "class", "style"]))
                  }), 128))
                : _createCommentVNode("", true)
            ]))
      ]),
      _: 2
    }, 1032, ["heading"]))
  }), 128))
}