
import BlockDisplayBlock, {
  IBlockItem,
} from '@/components/partials/BlockDisplayBlock.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'BlockDisplayWrap',
  props: {
    items: {
      type: Array as PropType<IBlockItem[]>,
      required: true,
    },
  },
  components: { BlockDisplayBlock },
})
