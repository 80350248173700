import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52fd162f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "BlockDisplayWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockDisplayBlock = _resolveComponent("BlockDisplayBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: item.title
      }, [
        (item.text)
          ? (_openBlock(), _createBlock(_component_BlockDisplayBlock, {
              key: item.title,
              item: item
            }, null, 8, ["item"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}