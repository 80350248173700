
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { paradigmStore } from '@/store'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneParadigm',
  computed: {
    ...mapState(paradigmStore, ['sections']),
  },
  methods: {
    ...mapActions(paradigmStore, ['isBlackListed']),
  },
  components: { PaneTitle },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
