
import Image from '@/components/Image.vue'
import PaneLineUnit from '@/components/panes/PaneLineUnit.vue'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { customJoin } from '@/globals/javascript/_utils/util'
import { IImage } from '@/__types__/_pro/partials/image'
import { currentStore } from '@/store'
import { orderBy } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import PageVideoGuide from '@/components/pageGuide/PageVideoGuide.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'

export default defineComponent({
  name: 'PaneBuildingsAndFloorplans',
  computed: {
    ...mapState(currentStore, {
      project: 'project',
      onlineTypes: 'onlineTypes',
      activeUnits: ({ units }) => units.active.all,
      customUnits: ({ units }) => units.custom,
      buildings: ({ units }) => units.active.buildings,
    }),
    cadastralMap(): IImage | null {
      return (
        this.project?.addressImages?.[0]?.overview?.cadastralMap?.[0] ?? null
      )
    },
    cadastralCaption(): string {
      const customBuildings = this.customUnits.filter(
        (x) => x instanceof Building
      ) as Building[]

      if (customBuildings.length === 0) {
        return ''
      }

      const injection = customJoin(
        customBuildings.map((x) => x.getTitle()),
        ', ',
        ` ${this.mixWB('AND')} `
      )

      return this.mixWB('X_NOT_FOUND_IN_BBR_WARNING_TEXT', [injection])
    },
  },
  methods: {
    getFloors(ids: string[]) {
      const units = ids
        .map((id) => this.activeUnits.find((x) => x.id === id))
        .filter(Boolean) as Floor[]

      return orderBy(units, 'data.numericFloorValue', 'asc')
    },
    getApartments(ids: string[]) {
      const units = ids
        .map((id) => this.activeUnits.find((x) => x.id === id))
        .filter(Boolean) as Apartment[]

      return orderBy(units, 'data.title', 'asc')
    },
    getTypesCount(unitID: string) {
      const relatedTypes = this.onlineTypes.filter((o) =>
        o.unitIDs.includes(unitID)
      )
      return relatedTypes.length
    },
    getCountText(count: number) {
      return count === 1
        ? this.mixWB('1_TYPE')
        : this.mixWB('X_TYPES', [count.toString()])
    },
  },
  components: { PaneLineUnit, Image, PageVideoGuide, PaneTitle },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
