import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c0b1ace"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneBuildingsAndFloorplans CustomScrollbar" }
const _hoisted_2 = { class: "PaneIntro" }
const _hoisted_3 = { class: "List" }
const _hoisted_4 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_5 = { class: "Label" }
const _hoisted_6 = { class: "Checkmark" }
const _hoisted_7 = { class: "Label" }
const _hoisted_8 = {
  key: 0,
  class: "PaneEmptyText"
}
const _hoisted_9 = { class: "ListContent" }
const _hoisted_10 = {
  key: 0,
  class: "List"
}
const _hoisted_11 = { class: "ListLabels PaneLine Disabled" }
const _hoisted_12 = { class: "Label" }
const _hoisted_13 = { class: "ImageWrap" }
const _hoisted_14 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_PageVideoGuide = _resolveComponent("PageVideoGuide")!
  const _component_PaneLineUnit = _resolveComponent("PaneLineUnit")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _component_Image = _resolveComponent("Image")!
  const _directive_gallery = _resolveDirective("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PaneTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.mixWB('BUILDINGS_AND_FLOOR_PLANS')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('BUILDINGS_PAGE_INTRO_TEXT_1')), 1),
      _createVNode(_component_PageVideoGuide, { guide: "units" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.mixWB('BUILDINGS_AND_UNITS')), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.mixWB('FLOOR_PLAN')), 1)
        ])
      ]),
      (!_ctx.buildings.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.mixWB('NO_UNITS_SELECTED_YET')), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buildings, (building) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: building.hashID
          }, [
            _createVNode(_component_PaneLink, {
              class: "PaneLine Building",
              "pane-id": building.hashID
            }, {
              default: _withCtx(() => [
                _createVNode(_component_PaneLineUnit, {
                  unit: building,
                  countText: _ctx.getCountText(_ctx.getTypesCount(building.id)),
                  showImage: true
                }, null, 8, ["unit", "countText"])
              ]),
              _: 2
            }, 1032, ["pane-id"]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFloors(building.floorIDs), (floor) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: floor.hashID
              }, [
                _createVNode(_component_PaneLink, {
                  class: "PaneLine Floor",
                  "pane-id": floor.hashID
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PaneLineUnit, {
                      unit: floor,
                      countText: _ctx.getCountText(_ctx.getTypesCount(floor.id)),
                      showImage: false
                    }, null, 8, ["unit", "countText"])
                  ]),
                  _: 2
                }, 1032, ["pane-id"]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getApartments(floor.apartmentIDs), (apartment) => {
                  return (_openBlock(), _createBlock(_component_PaneLink, {
                    key: apartment.hashID,
                    class: "PaneLine Apartment",
                    "pane-id": apartment.hashID
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PaneLineUnit, {
                        unit: apartment,
                        countText: _ctx.getCountText(_ctx.getTypesCount(apartment.id)),
                        showImage: false
                      }, null, 8, ["unit", "countText"])
                    ]),
                    _: 2
                  }, 1032, ["pane-id"]))
                }), 128))
              ], 64))
            }), 128))
          ], 64))
        }), 128))
      ])
    ]),
    (_ctx.cadastralMap)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.mixWB('CADASTRAL_MAP')), 1)
          ]),
          _createElementVNode("figure", _hoisted_13, [
            _withDirectives(_createVNode(_component_Image, {
              image: _ctx.cadastralMap,
              transformation: 'square_360',
              dimensions: { width: 360, height: 360 }
            }, null, 8, ["image"]), [
              [_directive_gallery, 'cadastralMap']
            ]),
            (_ctx.cadastralCaption)
              ? (_openBlock(), _createElementBlock("figcaption", _hoisted_14, _toDisplayString(_ctx.cadastralCaption), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}