import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce899ed6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "GuideContent" }
const _hoisted_2 = {
  key: 0,
  class: "Title"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: "PageVideoGuide",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setShownDialog(_ctx.guide + 'VideoGuide')))
    }, [
      _createVNode(_component_InlineSVG, {
        class: "Icon",
        src: require('@/assets/svg/video-small-2.svg')
      }, null, 8, ["src"]),
      _createElementVNode("span", null, _toDisplayString(_ctx.mixWB('ABOUT_THE_PAGE')), 1)
    ]),
    _createVNode(_component_Dialog, {
      name: _ctx.guide + 'VideoGuide',
      hasEasyClose: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_VideoPlayer, { video: _ctx.guide }, null, 8, ["video"]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.text, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.content
            }, [
              (item.title)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.content), 1))
                : (_openBlock(), _createElementBlock("p", {
                    key: 1,
                    innerHTML: item.content
                  }, null, 8, _hoisted_3))
            ], 64))
          }), 128))
        ]),
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["name"])
  ], 64))
}