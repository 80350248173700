import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2269c346"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "Status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_InlineSVG = _resolveComponent("InlineSVG")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Image, {
      image: _ctx.onlineType.types[0].images[0],
      dimensions: {width: 40, height: 40},
      transformation: 'square_40'
    }, null, 8, ["image"]),
    _createElementVNode("div", null, [
      (_ctx.prefix)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.prefix) + ": ", 1))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_ctx.onlineType.titles.complete), 1)
    ]),
    _createVNode(_component_ToolTip, null, {
      message: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.onlineType.status.text), 1)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.onlineType.status.className)
          }, null, 2)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_InlineSVG, {
      class: "Icon",
      src: require('@/assets/svg/angle-right.svg')
    }, null, 8, ["src"])
  ], 64))
}