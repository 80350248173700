import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b95df81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneResaleOnlineType CustomScrollbar" }
const _hoisted_2 = { class: "Meta" }
const _hoisted_3 = { class: "Legend" }
const _hoisted_4 = { class: "Legend" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "Legend" }
const _hoisted_7 = {
  key: 0,
  class: "MarketInquiry"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaneTitle = _resolveComponent("PaneTitle")!
  const _component_Image = _resolveComponent("Image")!
  const _component_CustomSlider = _resolveComponent("CustomSlider")!
  const _component_MarketInquiry = _resolveComponent("MarketInquiry")!
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_ToolTip = _resolveComponent("ToolTip")!
  const _component_BlockDisplayWrap = _resolveComponent("BlockDisplayWrap")!
  const _component_Region = _resolveComponent("Region")!
  const _component_Information = _resolveComponent("Information")!
  const _component_PaneLineInterConnection = _resolveComponent("PaneLineInterConnection")!
  const _component_PaneLink = _resolveComponent("PaneLink")!
  const _directive_gallery = _resolveDirective("gallery")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.oType)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_PaneTitle, { paneID: _ctx.id }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.oType.titles.complete), 1)
            ]),
            _: 1
          }, 8, ["paneID"]),
          _withDirectives(_createVNode(_component_CustomSlider, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
                return (_openBlock(), _createBlock(_component_Image, {
                  key: image.base.path,
                  image: image,
                  dimensions: { width: 200, height: 200 },
                  transformation: 'square_200',
                  class: "Image_200"
                }, null, 8, ["image"]))
              }), 128))
            ]),
            _: 1
          }, 512), [
            [_directive_gallery, _ctx.id]
          ]),
          _createElementVNode("dl", _hoisted_2, [
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_3, _toDisplayString(_ctx.mixWB('STATUS')) + ":", 1),
              _createElementVNode("dd", null, [
                _createElementVNode("span", {
                  class: _normalizeClass(["Label", _ctx.oType.status.className])
                }, _toDisplayString(_ctx.oType.status.text), 3)
              ])
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("dt", _hoisted_4, _toDisplayString(_ctx.mixWB('UNITS')) + ":", 1),
              _createElementVNode("dd", null, _toDisplayString(_ctx.oType.unitsAsString), 1)
            ]),
            (_ctx.oType.rooms.listAsText)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("dt", _hoisted_6, _toDisplayString(_ctx.mixWB('ROOMS')) + ":", 1),
                  _createElementVNode("dd", null, _toDisplayString(_ctx.oType.rooms.listAsText), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.showMarketInquiryButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_MarketInquiry, { productDetails: _ctx.productDetails }, null, 8, ["productDetails"]),
                _createVNode(_component_ToolTip, {
                  placement: 'below',
                  forceSide: 'right',
                  isOnlyClick: true
                }, {
                  message: _withCtx(() => [
                    _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('GIVE_OFFER_INFO_TEXT_1')), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('GIVE_OFFER_INFO_TEXT_2')), 1)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("button", null, [
                      _createVNode(_component_InlineSVG, {
                        src: require('@/assets/svg/question.svg')
                      }, null, 8, ["src"])
                    ])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_Region, {
            heading: _ctx.mixWB('QUANTITIES')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BlockDisplayWrap, { items: _ctx.quantityItems }, null, 8, ["items"])
            ]),
            _: 1
          }, 8, ["heading"]),
          _createVNode(_component_Region, {
            heading: _ctx.mixWB('CO2_E_SAVINGS')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BlockDisplayWrap, { items: _ctx.co2Items }, null, 8, ["items"])
            ]),
            _: 1
          }, 8, ["heading"]),
          (_ctx.conditionItems.length)
            ? (_openBlock(), _createBlock(_component_Region, {
                key: 1,
                heading: _ctx.mixWB('CONDITION')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BlockDisplayWrap, { items: _ctx.conditionItems }, null, 8, ["items"])
                ]),
                _: 1
              }, 8, ["heading"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Information, {
            title: _ctx.mixWB('COMMENTS_2'),
            texts: _ctx.oType.getTypeComments() || []
          }, null, 8, ["title", "texts"]),
          _createVNode(_component_Information, {
            title: _ctx.mixWB('PROFESSIONAL_ASSESSMENTS'),
            texts: _ctx.informationTexts
          }, null, 8, ["title", "texts"]),
          _createVNode(_component_Region, {
            heading: _ctx.mixWB('VIEW_ENVIRONMENTAL_DOCUMENTATION'),
            clearSpacing: true
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PaneLink, {
                class: "PaneLine",
                "pane-id": _ctx.oType.hashID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_PaneLineInterConnection, { "online-type": _ctx.oType }, null, 8, ["online-type"])
                ]),
                _: 1
              }, 8, ["pane-id"])
            ]),
            _: 1
          }, 8, ["heading"])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}