
import Image from '@/components/Image.vue'
import ToolTip from '@/components/ToolTip.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaneLineOnlineType',
  props: {
    onlineType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
    inResaleContext: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { Image, ToolTip },
})
