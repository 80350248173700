
import Image from '@/components/Image.vue'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaneLineUnit',
  props: {
    unit: {
      type: Object as PropType<Building | Apartment | Floor>,
      required: true,
    },
    showImage: {
      type: Boolean,
      required: true,
    },
    countText: {
      type: String,
      required: true,
    },
  },
  data() {
    const image =
      this.unit instanceof Building
        ? this.unit.getProfileImages()[0] ?? null
        : null

    return {
      image,
    }
  },
  computed: {
    unitType() {
      return this.mixWB(this.unit.type.toUpperCase()).toLowerCase()
    },
  },
  components: { Image },
})
