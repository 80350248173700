import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-010d0499"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneTitle" }
const _hoisted_2 = { class: "Heading1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InlineSVG = _resolveComponent("InlineSVG")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    (_ctx.showBackLink)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.href,
          class: "BackLink"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InlineSVG, {
              src: require('@/assets/svg/angle-left.svg')
            }, null, 8, ["src"])
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createElementVNode("h1", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}