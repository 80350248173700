
import FeaturedOnlineType from '@/components/FeaturedOnlineType.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneFeaturedResale',
  computed: {
    ...mapState(currentStore, {
      resale: 'resale',
    }),
  },
  components: { FeaturedOnlineType },
})
