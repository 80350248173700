
import MarketInquiryFlow from '@/components/marketInquiry/MarketInquiryFlow.vue'
import Dialog from '@/components/modals/Dialog.vue'
import { appStore } from '@/store'
import { mapActions } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ContactEmbed',
  props: {
    productDetails: {
      type: Object as PropType<{
        title: string
        url: string
        imageURL: string
      }>,
      required: true,
    },
  },
  methods: {
    ...mapActions(appStore, ['setShownDialog']),
  },
  components: { Dialog, MarketInquiryFlow },
})
