
import ToolTip from '@/components/ToolTip.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import { defineComponent, PropType } from 'vue'
import { compare } from 'compare-versions'
import { mapState } from 'pinia'
import { currentStore } from '@/store'
import versionKeys from '@/globals/javascript/constants/versionKeys'
import { IAnalysisTestGroupSummmary } from '@/__types__/proSample'

export default defineComponent({
  name: 'PaneLineSample',
  props: {
    sample: {
      type: Object as PropType<ProSample>,
      required: true,
    },
    equivalentBase: {
      type: Array as PropType<ProSample[]>,
      required: false,
      default: () => [],
    },
  },
  data() {
    const { sampleType } = this.sample

    return {
      sampleType,
      captionText: this.caption(),
    }
  },
  computed: {
    ...mapState(currentStore, ['projectProVersion', 'usedAnalysisTestGroups']),
    paneLineIsActive() {
      const { paneIDs } = this.$route.params

      if (typeof paneIDs === 'string') {
        return false
      }

      return paneIDs.includes(this.sample.hashID)
    },
    summmaryDots(): IAnalysisTestGroupSummmary[] {
      return this.sample
        .getAnalysisTestGroupSummaries()
        .filter(
          (summary) =>
            !!this.usedAnalysisTestGroups.find((x) => x.id === summary.id)
        )
    },
    equivalentsText(): string {
      // VERSION CHECK: Same or newer than 2.8.4
      if (
        compare(
          this.projectProVersion,
          versionKeys.HIDE_EQUIVALENT_NUMBERS,
          '>='
        )
      ) {
        const { otherScreeningID } = this.sample

        if (!otherScreeningID) {
          return ''
        }

        return `${this.mixWB('ORIGINAL_SAMPLE')}: ${this.sample.webID.origin}`
      }

      // VERSION CHECK: Same or older than 2.8.4
      else {
        const { otherScreeningID, projectID, id } = this.sample

        // A sample can only reference one sample
        if (otherScreeningID) {
          return `${this.mixWB('ORIGINAL_SAMPLE')}: ${this.sample.webID.origin}`
        }

        // Short circuit if no samples provided
        if (!this.equivalentBase.length) {
          return ''
        }

        // Get related equivalent samples from same project only
        const equivalents = this.equivalentBase.filter((s) => {
          return s.equivalentSampleID === id && s.projectID === projectID
        })

        if (!equivalents.length) {
          return ''
        }

        // Samples from same project
        const preText =
          equivalents.length === 1
            ? 'CORRESPONDING_SAMPLE'
            : 'CORRESPONDING_SAMPLES'

        const sampleIDs = equivalents
          .filter((e) => !e.otherScreeningID)
          .map((e) => e.webID.type)
          .join(', ')

        return `${this.mixWB(preText)}: ${sampleIDs}`
      }
    },
    title() {
      return this.sample.getFullTitle(true)
    },
  },
  methods: {
    caption() {
      const { sampleType, kindOfSample } = this.sample

      switch (sampleType) {
        case 'assessed':
          return kindOfSample === 'material'
            ? this.mixWB('ASSESSMENT_OF_MATERIAL')
            : this.mixWB('ASSESSMENT_OF_COATING')

        case 'existing':
          return kindOfSample === 'material'
            ? this.mixWB('RESULT_FOR_MATERIAL')
            : this.mixWB('RESULT_FOR_COATING')

        case 'standard':
        case 'equivalent':
        case 'postponed':
          return kindOfSample === 'material'
            ? this.mixWB('SAMPLE_OF_MATERIAL')
            : this.mixWB('SAMPLE_OF_COATING')
      }
    },
  },
  components: { ToolTip },
})
