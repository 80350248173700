import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8168548a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "FeaturedOnlineType" }
const _hoisted_2 = { class: "Content" }
const _hoisted_3 = {
  key: 0,
  class: "Amount"
}
const _hoisted_4 = { class: "Information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Image, {
      key: _ctx.image.base.path,
      image: _ctx.image,
      dimensions: { width: 200, height: 200 },
      transformation: 'square_200',
      class: "Image_200"
    }, null, 8, ["image"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showAmountText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.amountText), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.information.title), 1),
        _createElementVNode("span", null, _toDisplayString(_ctx.information.material), 1)
      ])
    ])
  ]))
}