import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7231a688"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneFeaturedResale CustomScrollbar" }
const _hoisted_2 = { class: "Heading1" }
const _hoisted_3 = { class: "PaneIntro" }
const _hoisted_4 = {
  key: 0,
  class: "List"
}
const _hoisted_5 = { class: "FeaturedTitleLine" }
const _hoisted_6 = { class: "Label" }
const _hoisted_7 = { class: "FeaturedResaleItems" }
const _hoisted_8 = {
  key: 1,
  class: "List"
}
const _hoisted_9 = { class: "ListLabels PaneLine Disabled TitleLine" }
const _hoisted_10 = { class: "Label" }
const _hoisted_11 = { class: "PaneEmptyText" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeaturedOnlineType = _resolveComponent("FeaturedOnlineType")!
  const _component_PaneLink = _resolveComponent("PaneLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.mixWB('FEATURED_RESALE_ITEMS')), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", null, _toDisplayString(_ctx.mixWB('FEATURED_RESALE_PAGE_INTRO_TEXT_1')), 1)
    ]),
    (_ctx.resale.highlighted.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.mixWB('ALL')) + " (" + _toDisplayString(_ctx.resale.highlighted.length) + ")", 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resale.highlighted, (oType) => {
              return (_openBlock(), _createBlock(_component_PaneLink, {
                key: oType.resaleID,
                "pane-id": oType.resaleID
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FeaturedOnlineType, { "online-type": oType }, null, 8, ["online-type"])
                ]),
                _: 2
              }, 1032, ["pane-id"]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.mixWB('RESALE_ITEMS')), 1)
          ]),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.mixWB('NO_RESALE_ITEMS_FOUND')), 1)
        ]))
  ]))
}