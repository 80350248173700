
import { IImage } from '@/__types__/_pro/partials/image'
import { appStore, currentStore } from '@/store'
import { uniqBy } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import PaneLineInterConnection from '@/components/panes/PaneLineInterConnection.vue'
import TestResultGrid from '@/components/partials/TestResultGrid.vue'
import Region from '@/components/Region.vue'
import { formatEWCNumber } from '@/globals/javascript/_utils/util'
import FloorPlans from '@/components/FloorPlans.vue'
import Information from '@/components/partials/Information.vue'
import { IInfoText } from '@/__types__/utils/infoText'
import { IBlockItem } from '@/components/partials/BlockDisplayBlock.vue'
import BlockDisplayWrap from '@/components/partials/BlockDisplayWrap.vue'
import CustomSlider from '@/components/CustomSlider.vue'
import Image from '@/components/Image.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'

export default defineComponent({
  name: 'PaneOnlineType',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(appStore, ['isResale']),
    ...mapState(currentStore, ['onlineTypes']),
    oType(): OnlineType | null {
      return this.onlineTypes.find((oType) => oType.hashID === this.id) ?? null
    },
    images(): IImage[] {
      if (!this.oType) {
        return []
      }

      return uniqBy(
        this.oType.types
          .map((pType) => pType.images)
          .flat(1)
          .filter(Boolean),
        'base.path'
      )
    },
    details(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      const { titles, ewcCode } = this.oType

      /**
       * Fraction
       */
      const fraction: IBlockItem = {
        title: this.mixWB('FRACTION'),
        text: titles.fraction ?? '-',
        isFullWidth: false,
        extraTexts: [],
      }

      if (this.oType.status.className === 'Hazardous') {
        fraction.isBold = true
      }
      /**
       * EWC Code
       */
      const ewcCodeObj: IBlockItem = {
        title: this.mixWB('EWC_CODE'),
        text: ewcCode?.id ? formatEWCNumber(ewcCode.id) : '-',
        isFullWidth: false,
        extraTexts: [],
      }

      if (this.oType.status.className === 'Hazardous') {
        ewcCodeObj.isBold = true
      }

      return [fraction, ewcCodeObj]
    },
    quantityItems(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      /**
       * Amount
       */
      const amountTitle =
        this.oType.amounts.totalTons && this.oType.amounts.hasMissingAmounts
          ? `${this.mixWB('ESTIMATED_QUANTITY')} (${this.mixWB(
              'CERTAIN_QUANTITIES_ARE_MISSING'
            )})`
          : this.mixWB('ESTIMATED_QUANTITY')

      const amount: IBlockItem = {
        title: amountTitle,
        text: this.oType.getTonsTextWithDetails(),
        isFullWidth: true,
        extraTexts: [],
      }

      this.oType.amounts.amountsPerPType.forEach((pTypeAmountItem) => {
        amount.extraTexts.push(pTypeAmountItem.calculation)
      })

      this.oType.amounts.amountsPerCleaningTask.forEach((cleaningTaskItem) => {
        amount.extraTexts.push(cleaningTaskItem.calculation)
      })

      return [amount]
    },
    co2Items(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      const { co2Amounts } = this.oType

      const title =
        co2Amounts.totalTons && co2Amounts.hasMissingAmounts
          ? `${this.mixWB('POTENTIAL_SAVINGS')} (${this.mixWB(
              'CERTAIN_QUANTITIES_ARE_MISSING'
            )})`
          : this.mixWB('POTENTIAL_SAVINGS')

      const amount: IBlockItem = {
        title,
        text: this.oType.getCO2TextWithDetails(),
        isFullWidth: true,
        extraTexts: [],
        footNotes: [],
      }

      co2Amounts.amountsPerPType.forEach((pTypeAmountItem) => {
        amount.extraTexts.push(pTypeAmountItem.texts.calculation)
      })

      amount.footNotes?.push({
        pretext: this.mixWB('READ_MORE_ABOUT'),
        link: {
          href: '/project/J0ffoDhhcDJ04rVgTcqh/paradigm/global-warming-potential',
          text: this.mixWB('CO2_E_CALCULATION'),
        },
      })

      return [amount]
    },
    conditionItems() {
      if (!this.oType) {
        return []
      }

      const forceConditions =
        this.oType.types.length > 1 && this.oType.types.some((x) => x.condition)

      const conditions = this.oType.types.reduce((prev, proType) => {
        // Add to view if forced and the original proType or if condition i present
        if ((forceConditions && !proType.originID) || proType.condition) {
          prev.push({
            title: proType.getTitle(),
            text: proType.getConditionText() ?? '',
            isFullWidth: true,
            extraTexts: [],
          })
        }

        return prev
      }, [] as IBlockItem[])

      return conditions
    },
    preventionBox(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      if (this.oType.courseOfAction === 'prevention') {
        return [
          {
            title: '',
            text: this.mixWB('NOT_TO_BE_DEMOLISHED'),
            isFullWidth: true,
            extraTexts: [],
          },
        ]
      }

      return []
    },
    reuseBox(): IBlockItem[] {
      if (!this.oType) {
        return []
      }

      if (this.oType.courseOfAction === 'reuse') {
        return [
          {
            title: '',
            text: this.mixWB('TO_BE_REUSED'),
            isFullWidth: true,
            extraTexts: [],
          },
        ]
      }

      return []
    },
  },
  methods: {
    informationTexts() {
      if (!this.oType) {
        return []
      }

      const onlineTypeInfo = this.oType.getInformationTexts()

      // If NOT resale
      if (!this.isResale) {
        return onlineTypeInfo
      }

      let resaleInfo: IInfoText[] = []

      // If resale - preprend resale specific text
      if (this.oType.recycleCases.length) {
        resaleInfo.push({
          type: 'header',
          text: this.mixWB('RESERVATIONS_FOR_RESALE'),
        })

        this.oType.recycleCases.forEach((rCase) => {
          let caseText = ''

          switch (rCase) {
            case 'recycle-case-pah-and-ch':
              caseText =
                'THIS_TYPE_CONTAINS_HIGH_QUANTITIES_OF_PAH_AND_HYDROCARBONS'
              break

            case 'recycle-case-pah':
              caseText = 'THIS_TYPE_CONTAINS_HIGH_QUANTITIES_OF_PAH'
              break

            case 'recycle-case-ch':
              caseText = 'THIS_TYPE_CONTAINS_HIGH_QUANTITIES_OF_HYDROCARBONS'
              break
          }

          resaleInfo.push({ text: this.mixWB(caseText) })
        })

        resaleInfo.push({
          text: this.mixWB(
            'ENVIRONMENTAL_REMEDIATION_NEEDED_BEFORE_REUSE_TEXT'
          ),
        })
      }

      return [...resaleInfo, ...onlineTypeInfo]
    },
  },
  components: {
    PaneLineInterConnection,
    TestResultGrid,
    Region,
    BlockDisplayWrap,
    FloorPlans,
    Information,
    CustomSlider,
    Image,
    PaneTitle,
  },
  created() {
    if (!this.oType) {
      this.$emit('data-not-found')
    }
  },
})
