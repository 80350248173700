
import Image from '@/components/Image.vue'
import OnlineType from '@/globals/javascript/models/onlineType/OnlineType'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'PaneLineRemediation',
  props: {
    onlineType: {
      type: Object as PropType<OnlineType>,
      required: true,
    },
  },
  components: { Image },
})
