import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b51d356"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "Region" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      class: _normalizeClass(["Label", { 'HeaderSpacing': !_ctx.clearSpacing }])
    }, _toDisplayString(_ctx.heading), 3),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}