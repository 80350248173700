
import PageVideoGuide from '@/components/pageGuide/PageVideoGuide.vue'
import PaneLineSample from '@/components/panes/PaneLineSample.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { currentStore } from '@/store'
import { orderBy } from 'lodash-es'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneListSamples',
  computed: {
    ...mapState(currentStore, {
      usedAnalysisTestGroups: 'usedAnalysisTestGroups',
      projectSamples: ({ samples }) => samples.all,
      samples: ({ samples, samplesByBuilding, buildingFilterApplied }) => {
        // Return all normal samples
        if (!buildingFilterApplied) {
          return samples.standard
        }

        // Find all samples that are related to the current selection
        const internalEquivalentIDs = samplesByBuilding.equivalent
          .filter((x) => x.options.isInternalEquivalent)
          .map((x) => x.equivalentSampleID)

        const relatedSamples = samples.standard.filter((x) =>
          internalEquivalentIDs.includes(x.id)
        )

        const relevantSamples = [
          ...samplesByBuilding.standard,
          ...relatedSamples,
        ]

        return orderBy(relevantSamples, 'sampleNumber', 'asc')
      },
      assesments: ({ samplesByBuilding }) => samplesByBuilding.assessed,
      externalEquivalents: ({ samplesByBuilding }) =>
        samplesByBuilding.equivalent.filter((x) => x.otherScreeningID),
      existing: ({ samplesByBuilding }) => samplesByBuilding.existing,
      postponed: ({ samplesByBuilding }) => samplesByBuilding.postponed,
    }),
  },
  components: { PaneLineSample, PageVideoGuide, PaneTitle },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
