
import PageVideoGuide from '@/components/pageGuide/PageVideoGuide.vue'
import PaneLineFraction from '@/components/panes/PaneLineFraction.vue'
import PaneTitle from '@/components/panes/PaneTitle.vue'
import { currentStore } from '@/store'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PaneListFractions',
  computed: {
    ...mapState(currentStore, {
      allFractions: ({ fractionGroupsByBuilding }) => {
        return fractionGroupsByBuilding.all
      },
      preventionFraction: ({ fractionGroupsByBuilding }) => {
        return fractionGroupsByBuilding.prevention[0]
      },
      unknownFraction: ({ fractionGroupsByBuilding }) => {
        return fractionGroupsByBuilding.unknown[0]
      },
      reuseFraction: ({ fractionGroupsByBuilding }) => {
        return fractionGroupsByBuilding.reuse[0]
      },
      recycleFractions: ({ fractionGroupsByBuilding }) => {
        // Return those without a group
        return fractionGroupsByBuilding.remaining.filter(
          (x) => !['F-7', 'F-8', 'F-12', 'F-5', 'F-9'].includes(x.hashID)
        )
      },
      disposalFractions: ({ fractionGroupsByBuilding }) => {
        // Non-combustables and asbestos
        return fractionGroupsByBuilding.remaining.filter((x) =>
          ['F-7', 'F-8', 'F-12'].includes(x.hashID)
        )
      },
      utilizationFractions: ({ fractionGroupsByBuilding }) => {
        // Combustables
        return fractionGroupsByBuilding.remaining.filter((x) =>
          ['F-5', 'F-9'].includes(x.hashID)
        )
      },
    }),
  },
  components: { PaneLineFraction, PageVideoGuide, PaneTitle },
  mounted() {
    // Using hook:mounted since the VueJS API should have this.
    // https://github.com/vuejs/vue-next/blob/f454dd62ab689b348902b01f849ca5347e91ffae/packages/runtime-core/src/renderer.ts#L1422
    // setTimeout is to add the emit at the first idle CPU time
    setTimeout(() => {
      this.$emit('hook:mounted')
    }, 0)
  },
})
