
import Image from '@/components/Image.vue'
import Region from '@/components/Region.vue'
import ProSample from '@/globals/javascript/models/ProSample/ProSample'
import Apartment from '@/globals/javascript/models/proUnit/Apartment'
import Building from '@/globals/javascript/models/proUnit/Building'
import Floor from '@/globals/javascript/models/proUnit/Floor'
import Unit from '@/globals/javascript/models/proUnit/Unit'
import { ENV } from '@/globals/javascript/_utils/env'
import { currentStore } from '@/store'
import { IFloorplan, IFloorplanDot } from '@/__types__/floorPlan'
import { mapState } from 'pinia'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'FloorPlans',
  emits: ['select'],
  props: {
    samples: {
      type: Object as PropType<ProSample[]>,
      required: false,
    },
    unit: {
      type: Object as PropType<Building | Apartment | Floor>,
      required: false,
    },
    hideSamples: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    if (
      ENV.isDevelopment &&
      this.samples === undefined &&
      this.unit === undefined
    ) {
      throw new Error(
        'FloorPlans.vue: Either samples or unit must contain a relevant instance'
      )
    }

    return {
      floorplansReady: [] as string[],
    }
  },
  computed: {
    ...mapState(currentStore, {
      mappedSamples: ({ samples }) => samples.standard,
    }),
    hasPostponedSample() {
      return this.samples?.some((x) => x.sampleType === 'postponed') ?? false
    },
    floorplans(): IFloorplan[] {
      if (this.unit) {
        return this.unitFloorPlan
      }

      if (this.samples) {
        return this.sampleFloorplans
      }

      return []
    },
    unitFloorPlan(): IFloorplan[] {
      if (this.unit === undefined) {
        return [] as IFloorplan[]
      }

      const [floorPlan] = this.unit.getFloorPlan()
      const unitSamples = this.mappedSamples.filter(
        (x) => x.floorPlanUnit === this.unit?.id
      )
      const dots = this.hideSamples ? [] : unitSamples.map(this.fromSampleToDot)

      return [
        {
          id: this.unit.id,
          floorPlan,
          unit: this.unit,
          dots,
        },
      ]
    },
    sampleFloorplans(): IFloorplan[] {
      const data = {} as { [key: string]: IFloorplan }

      if (this.hasPostponedSample) {
        return [] as IFloorplan[]
      }

      if (!this.samples) {
        return [] as IFloorplan[]
      }

      this.samples.forEach((sample) => {
        const { floorPlanUnit, otherScreeningID } = sample

        // Don't show floor plans from other projects
        if (otherScreeningID) {
          return
        }

        // If no floor plan is assigned
        if (!floorPlanUnit) {
          return
        }

        // Get unit where sample was taken
        const unit = Unit.getUnitById(floorPlanUnit)

        if (!unit) {
          return
        }

        // Get first floorplan
        const [floorPlan] = unit.getFloorPlan()

        // Connect related floorplan and sample
        if (!data[floorPlanUnit]) {
          data[floorPlanUnit] = {
            id: floorPlanUnit,
            floorPlan,
            unit,
            dots: this.hideSamples ? [] : [this.fromSampleToDot(sample)],
          }

          return
        }

        if (!this.hideSamples) {
          data[floorPlanUnit].dots.push(this.fromSampleToDot(sample))
        }
      })

      return Object.values(data)
    },
  },
  methods: {
    fromSampleToDot(sample: ProSample): IFloorplanDot {
      // Ålholm Slots historiske støv prøver
      const dotClass = sample.options.isAalholmSlotDustSample
        ? this.mixContaminationClass('')
        : this.mixContaminationClass(sample.wdcID)

      return {
        hashID: sample.hashID,
        class: dotClass,
        text: sample.sampleTypeID,
        position: sample.floorPlanPosition,
      }
    },
    floorPlanLoaded(id: string) {
      this.floorplansReady.push(id)
    },
    getDotPositionStyle({ x, y }: { x: number; y: number }) {
      if (x === null || y === null) {
        return {}
      }

      // Floorplan images are always saved in Portrait (via PRO)
      // - Simply assign x on x-axis and y on y-axis
      return {
        top: `${y}%`,
        left: `${x}%`,
      }
    },
  },
  components: { Image, Region },
})
