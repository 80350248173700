import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25b1fa8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "PaneParadigm CustomScrollbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.paradigm)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.paradigm.component), {
          key: _ctx.id,
          index: _ctx.paradigm.index
        }, null, 8, ["index"]))
      : _createCommentVNode("", true)
  ]))
}